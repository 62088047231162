import uniqueId from 'lodash/uniqueId';
export default class CustomService {
  constructor(options = {}) {
    this.$options = options;
    const {
      // ID
      id = uniqueId('CustomService__'),

      /**
       * 状态
       * OFFLINE: 离开
       * BUSY: 忙碌
       * ONLINE: 空闲
       */
      status = '',
      // 客服名称
      name = '',
      // 所属分组ID
      belongGroupId = ''
    } = options;
    this.status = status;
    this.name = name;
    this.belongGroupId = belongGroupId;
    this.id = id;
  }
  // 根据会话的最后一条消息生成
  static withLastMsg({ sendUserId, csName }) {
    return new CustomService({
      id: sendUserId,
      name: csName
    });
  }
  // 会话分页 客服信息
  static withListPage(id, payload) {
    return new CustomService({
      id,
      ...payload
    });
  }
}
