import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import {
  filterMessage,
  FETCH_ERROR,
  FETCH_TIME_OUT,
  DISCONNECT_NETWORK,
  SERVICE_INSIDE_ERROR,
  ACCESS_DENIED
} from '@/constants/message';
import store from '../store';
import router from '../router';
import { getUserInfo } from '@/common/localStorage/user';
const defaultConfig = {
  processError: true, // 自动提示错误信息 默认打开
  process404: true, // 处理404
  useSSO: !!process.env.VUE_APP_USE_SSO_DEFAULT // 是否启用sso
};
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 'http://10.2.1.212',
  timeout: 30000, // 请求超时时间
  withCredentials: true,
  ...defaultConfig
});
let flag = false;
export class RequestError {
  constructor(key) {
    const self = this;
    self.initKey = key || FETCH_ERROR; // 初始化
    self.key = self.initKey;
  }
  set default (key) {
    // 替换当前类默认的错误提示
    const self = this;
    if (self.key === FETCH_ERROR) {
      self.key = key;
    }
  }
  get text () {
    const { key, message } = this;
    return message || filterMessage(key) || '';
  }
  set response (res) {
    const self = this;
    if (res.code === '500' && (!res.msg || res.msg.toLowerCase().indexOf('exception') !== -1)) {
      self.key = SERVICE_INSIDE_ERROR;
    } else if (res.code === '400') {
      self.key = ACCESS_DENIED;
    } else {
      // 取接口返回的错误
      self.message = res.msg;
    }
    self.data = res;
  }
  set error (error) {
    const self = this;
    const { request = {}, status: fecthStatus, message: fetchMessage } = error;
    const { status: reqStatus, message: reqMessage } = request;
    const status = reqStatus || fecthStatus;
    const message = reqMessage || fetchMessage || '';
    if (status === 500) {
      self.key = SERVICE_INSIDE_ERROR;
    } else if (status === 400) {
      // 默认
    } else if (message === 'Network Error') {
      self.key = DISCONNECT_NETWORK;
    } else if (message.indexOf('timeout') === 0) {
      self.key = FETCH_TIME_OUT;
    } else {
      // 取error信息
      self.message = error.message;
    }
    self.data = error;
  }
}

// 统一的头部获取
export const getHeaders = () => {
  const userInfo = getUserInfo();
  const headers = {
    'X-Tenant-Id': process.env.VUE_APP_TENANT_ID // 一级租户ID
  };
  // 灰度发布头部
  if (process.env.VUE_APP_ENV_CONFIG === 'uat') {
    headers.region = 'gray'; // 令牌
  }
  if (userInfo) {
    // headers['X-Csrf-Token'] = userInfo.csrfToken; // 令牌
    headers['X-User-Token'] = userInfo.userToken; // 令牌
  }
  return headers;
};

export function getHeadersWithExtTenant () {
  return {
    ...getHeaders(),
    'X-Ext-Tenant-Id': process.env.VUE_APP_TENANT_ID
  };
}

export const handleResponse = response => {
  // 兼容历史版本
  /**
   * code为非0是抛错 可结合自己业务进行修改
   */
  const res = response.data;
  if (res instanceof ArrayBuffer) {
    // 如果是文件流
    return res;
  }
  // 如果返回的是html文件
  const reg = RegExp(/text\/html/);
  if (reg.test(res)) {
    return res;
  }

  return getRes(res, response.config);
};
// 白名单code
const WHITE_CODES = ['0', 'UNSIGN001', 'DISTRIBUTOR_CODE_501', 'DISTRIBUTOR_CODE_502'];

export function getRes (res, config = defaultConfig) {
  // 这部分抽处理用于处理类似于图片上传之类的请求
  if (WHITE_CODES.includes(res.code)) {
    return Promise.resolve(res);
  }
  const { process404, processError, useSSO } = config;
  if (!flag && process404) {
    flag = true;
    if (res.code === '400') {
      MessageBox.alert('你已被登出，请重新登录', '确定登出', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          store.dispatch('FedLogOut').then(() => {
            const { history } = router;
            if (history) {
              const { fullPath } = history.current;
              const redirect = fullPath === '/syoung/user/shopList' ? '' : `?redirect=${fullPath}`; // 首页重定向 不要redirect
              const url = useSSO ? `/syoung/sso-login${redirect}` : `/syoung/login${redirect}`;
              location.href = url;
            }
          });
        }).catch(() => {
          store.dispatch('FedLogOut').then(() => {
            const { history } = router;
            if (history) {
              const { fullPath } = history.current;
              const redirect = fullPath === '/syoung/user/shopList' ? '' : `?redirect=${fullPath}`; // 首页重定向 不要redirect
              const url = useSSO ? `/syoung/sso-login${redirect}` : `/syoung/login${redirect}`;
              location.href = url;
            }
          });
        })
        .finally(() => {
          flag = false;
        });
    }
  }
  const requestError = new RequestError();
  requestError.response = res;
  if (processError) {
    if(requestError.data.code!=='400'){
      Message.error(requestError.text);
    }
  }
  return Promise.reject(requestError);
}

export const handleError = error => {
  const requestError = new RequestError();
  requestError.error = error;
  if ((error.config || defaultConfig).processError) {
    Message.error(requestError.text);
  }
  return Promise.reject(requestError);
};

// request拦截器
service.interceptors.request.use(
  config => {
    const { headers, url: oldUrl, ...args } = config;
    return {
      ...args,
      url: oldUrl.replace('@DEFAULT_SERVICE', process.env.VUE_APP_DEFAULT_SERVICE),
      headers: Object.assign({}, getHeaders(), headers)
    };
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response拦截器
service.interceptors.response.use(handleResponse, handleError);

export function parseUrl (url, data) {
  const keys = (data && Object.keys(data)) || [];
  const paramStr = keys.map(key => `${key}=${data[key]}`).join('&');
  return `${process.env.VUE_APP_BASE_API}${url}${paramStr && `?${paramStr}`}`;
}

export const closeErrorRequest = config => {
  // 不提示错误的request
  return service(
    Object.assign(config, {
      processError: false,
      useSSO: false
    })
  );
};

export function withExtTenantIdRequest (config) {
  return service({
    ...config,
    headers: {
      'X-Tenant-Id': process.env.VUE_APP_TENANT_ID ,// 一级租户ID
      'X-Client-Type': 'merchant_pc',
      'X-Ext-Tenant-Id': process.env.VUE_APP_TENANT_ID,
      ...config.headers
    }
  });
}
export function mockRequest (config) {
  return service({
    ...(process.env.VUE_APP_MOCK && {
      baseURL: 'https://easy-mock.com/mock/5cb9651018a7126a27520bc2/dazhou/example'
    }),
    ...config,
    headers: {
      'X-Ext-Tenant-Id': process.env.VUE_APP_TENANT_ID,
      ...config.headers
    }
  });
}

export default service;
