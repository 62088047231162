// 正整数（v-int）指令
import { trigger } from '@/utils';
const int = {
  bind: function (el) {
    const input = el.getElementsByTagName('input')[0];
    //  const maxLength = input.maxLength;
    input.onkeyup = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }
      input.value = trimCheck(input.value);
      trigger(input, 'input');
    };
    input.onblur = function (e) {
      if (input.value.length === 1) {
        input.value = input.value.replace(/[^0-9]/g, '');
      } else {
        input.value = input.value.replace(/[^\d]/g, '');
      }
      const reg1 = /0*([1-9]\d*|0\.\d+)/;
      const reg2 = /(?:\.0*|(\.\d+?)0+)$/;
      const value = input.value;
      input.value = value.replace(reg1, '$1').replace(reg2, '$1'); // 去掉数字（整数、小数）前面多余的零
      input.value = trimCheck(input.value);
      trigger(input, 'input');
    };
  }
};
export default int;

// 为空自动为0
function trimCheck(value) {
  if (value.trim() === '') {
    value = '0';
  }
  return value;
}
