import Vue from 'vue';
import Vuex from 'vuex';
import app from './modules/app';
import user from './modules/user';
import getters from './getters';
import design from './modules/design';
import chat from './modules/chat';
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
    user,
    design,
    chat
  },
  getters
});

export default store;
