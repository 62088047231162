export default function getEmotionsV1() {
  return [
    {
      code: '/::)',
      offset: 0,
      wx_key: 'icon_smiley_0',
      remark: '[微笑]'
    },
    {
      code: '/::~',
      offset: 1,
      wx_key: 'icon_smiley_1',
      remark: '[撇嘴]'
    },
    { code: '/::B', offset: 2, wx_key: 'icon_smiley_2', remark: '[色]' },
    {
      code: '/::|',
      offset: 3,
      wx_key: 'icon_smiley_3',
      remark: '[发呆]'
    },
    {
      code: '/:8-)',
      offset: 4,
      wx_key: 'icon_smiley_4',
      remark: '[得意]'
    },
    {
      code: '/::<',
      offset: 5,
      wx_key: 'icon_smiley_5',
      remark: '[流泪]'
    },
    {
      code: '/::$',
      offset: 6,
      wx_key: 'icon_smiley_6',
      remark: '[害羞]'
    },
    {
      code: '/::X',
      offset: 7,
      wx_key: 'icon_smiley_7',
      remark: '[闭嘴]'
    },
    { code: '/::Z', offset: 8, wx_key: 'icon_smiley_8', remark: '[睡]' },
    {
      code: "/::'(",
      offset: 9,
      wx_key: 'icon_smiley_9',
      remark: '[大哭]'
    },
    {
      code: '/::-|',
      offset: 10,
      wx_key: 'icon_smiley_10',
      remark: '[尴尬]'
    },
    {
      code: '/::@',
      offset: 11,
      wx_key: 'icon_smiley_11',
      remark: '[发怒]'
    },
    {
      code: '/::P',
      offset: 12,
      wx_key: 'icon_smiley_12',
      remark: '[调皮]'
    },
    {
      code: '/::D',
      offset: 13,
      wx_key: 'icon_smiley_13',
      remark: '[呲牙]'
    },
    {
      code: '/::O',
      offset: 14,
      wx_key: 'icon_smiley_14',
      remark: '[惊讶]'
    },
    {
      code: '/::(',
      offset: 15,
      wx_key: 'icon_smiley_15',
      remark: '[难过]'
    },
    {
      code: '[囧]',
      offset: 16,
      wx_key: 'icon_smiley_17',
      remark: '[冷汗]'
    },
    {
      code: '/::Q',
      offset: 17,
      wx_key: 'icon_smiley_18',
      remark: '[抓狂]'
    },
    {
      code: '/::T',
      offset: 18,
      wx_key: 'icon_smiley_19',
      remark: '[吐]'
    },
    {
      code: '/:,@P',
      offset: 19,
      wx_key: 'icon_smiley_20',
      remark: '[偷笑]'
    },
    {
      code: '/:,@-D',
      offset: 20,
      wx_key: 'icon_smiley_21',
      remark: '[愉快]'
    },
    {
      code: '/::d',
      offset: 21,
      wx_key: 'icon_smiley_22',
      remark: '[白眼]'
    },
    {
      code: '/:,@o',
      offset: 22,
      wx_key: 'icon_smiley_23',
      remark: '[傲慢]'
    },
    {
      code: '/:|-)',
      offset: 23,
      wx_key: 'icon_smiley_25',
      remark: '[困]'
    },
    {
      code: '/::!',
      offset: 24,
      wx_key: 'icon_smiley_26',
      remark: '[惊恐]'
    },
    {
      code: '/::L',
      offset: 25,
      wx_key: 'icon_smiley_27',
      remark: '[流汗]'
    },
    {
      code: '/::>',
      offset: 26,
      wx_key: 'icon_smiley_28',
      remark: '[憨笑]'
    },
    {
      code: '/::,@',
      offset: 27,
      wx_key: 'icon_smiley_29',
      remark: '[悠闲]'
    },
    {
      code: '/:,@f',
      offset: 28,
      wx_key: 'icon_smiley_30',
      remark: '[奋斗]'
    },
    {
      code: '/::-S',
      offset: 29,
      wx_key: 'icon_smiley_31',
      remark: '[咒骂]'
    },
    {
      code: '/:?',
      offset: 30,
      wx_key: 'icon_smiley_32',
      remark: '[疑问]'
    },
    {
      code: '/:,@x',
      offset: 31,
      wx_key: 'icon_smiley_33',
      remark: '[嘘]'
    },
    {
      code: '/:,@@',
      offset: 32,
      wx_key: 'icon_smiley_34',
      remark: '[晕]'
    },
    {
      code: '/:,@!',
      offset: 33,
      wx_key: 'icon_smiley_36',
      remark: '[衰]'
    },
    {
      code: '/:!!!',
      offset: 34,
      wx_key: 'icon_smiley_37',
      remark: '[骷髅]'
    },
    {
      code: '/:xx',
      offset: 35,
      wx_key: 'icon_smiley_38',
      remark: '[敲打]'
    },
    {
      code: '/:bye',
      offset: 36,
      wx_key: 'icon_smiley_39',
      remark: '[再见]'
    },
    {
      code: '/:wipe',
      offset: 37,
      wx_key: 'icon_smiley_40',
      remark: '[擦汗]'
    },
    {
      code: '/:dig',
      offset: 38,
      wx_key: 'icon_smiley_41',
      remark: '[抠鼻]'
    },
    {
      code: '/:handclap',
      offset: 39,
      wx_key: 'icon_smiley_42',
      remark: '[鼓掌]'
    },
    {
      code: '/:B-)',
      offset: 40,
      wx_key: 'icon_smiley_44',
      remark: '[坏笑]'
    },
    {
      code: '/:<@',
      offset: 41,
      wx_key: 'icon_smiley_45',
      remark: '[左哼哼]'
    },
    {
      code: '/:@>',
      offset: 42,
      wx_key: 'icon_smiley_46',
      remark: '[右哼哼]'
    },
    {
      code: '/::-O',
      offset: 43,
      wx_key: 'icon_smiley_47',
      remark: '[哈欠]'
    },
    {
      code: '/:>-|',
      offset: 44,
      wx_key: 'icon_smiley_48',
      remark: '[鄙视]'
    },
    {
      code: '/:P-(',
      offset: 45,
      wx_key: 'icon_smiley_49',
      remark: '[委屈]'
    },
    {
      code: "/::'|",
      offset: 46,
      wx_key: 'icon_smiley_50',
      remark: '[快哭了]'
    },
    {
      code: '/:X-)',
      offset: 47,
      wx_key: 'icon_smiley_51',
      remark: '[阴险]'
    },
    {
      code: '/::*',
      offset: 48,
      wx_key: 'icon_smiley_52',
      remark: '[亲亲]'
    },
    {
      code: '/:8*',
      offset: 49,
      wx_key: 'icon_smiley_54',
      remark: '[可怜]'
    },
    {
      code: '/:pd',
      offset: 50,
      wx_key: 'icon_smiley_55',
      remark: '[菜刀]'
    },
    {
      code: '/:',
      offset: 51,
      wx_key: 'icon_smiley_56',
      remark: '[西瓜]'
    },
    {
      code: '/:beer',
      offset: 52,
      wx_key: 'icon_smiley_57',
      remark: '[啤酒]'
    },
    {
      code: '/:coffee',
      offset: 53,
      wx_key: 'icon_smiley_60',
      remark: '[咖啡]'
    },
    {
      code: '/:pig',
      offset: 54,
      wx_key: 'icon_smiley_62',
      remark: '[猪头]'
    },
    {
      code: '/:rose',
      offset: 55,
      wx_key: 'icon_smiley_63',
      remark: '[玫瑰]'
    },
    {
      code: '/:fade',
      offset: 56,
      wx_key: 'icon_smiley_64',
      remark: '[凋谢]'
    },
    {
      code: '/:showlove',
      offset: 57,
      wx_key: 'icon_smiley_65',
      remark: '[嘴唇]'
    },
    {
      code: '/:heart',
      offset: 58,
      wx_key: 'icon_smiley_66',
      remark: '[爱心]'
    },
    {
      code: '/:break',
      offset: 59,
      wx_key: 'icon_smiley_67',
      remark: '[心碎]'
    },
    {
      code: '/:cake',
      offset: 60,
      wx_key: 'icon_smiley_68',
      remark: '[蛋糕]'
    },
    {
      code: '/:bome',
      offset: 61,
      wx_key: 'icon_smiley_70',
      remark: '[炸弹]'
    },
    {
      code: '/:shit',
      offset: 62,
      wx_key: 'icon_smiley_74',
      remark: '[便便]'
    },
    {
      code: '/:moon',
      offset: 63,
      wx_key: 'icon_smiley_75',
      remark: '[月亮]'
    },
    {
      code: '/:sun',
      offset: 64,
      wx_key: 'icon_smiley_76',
      remark: '[太阳]'
    },
    {
      code: '/:hug',
      offset: 65,
      wx_key: 'icon_smiley_78',
      remark: '[拥抱]'
    },
    {
      code: '/:strong',
      offset: 66,
      wx_key: 'icon_smiley_79',
      remark: '[强]'
    },
    {
      code: '/:weak',
      offset: 67,
      wx_key: 'icon_smiley_80',
      remark: '[弱]'
    },
    {
      code: '/:share',
      offset: 68,
      wx_key: 'icon_smiley_81',
      remark: '[握手]'
    },
    {
      code: '/:v',
      offset: 69,
      wx_key: 'icon_smiley_82',
      remark: '[胜利]'
    },
    {
      code: '/:@)',
      offset: 70,
      wx_key: 'icon_smiley_83',
      remark: '[抱拳]'
    },
    {
      code: '/:jj',
      offset: 71,
      wx_key: 'icon_smiley_84',
      remark: '[勾引]'
    },
    {
      code: '/:@@',
      offset: 72,
      wx_key: 'icon_smiley_85',
      remark: '[拳头]'
    },
    {
      code: '/:ok',
      offset: 73,
      wx_key: 'icon_smiley_89',
      remark: '[OK]'
    },
    {
      code: '/:jump',
      offset: 74,
      wx_key: 'icon_smiley_92',
      remark: '[跳跳]'
    },
    {
      code: '/:shake',
      offset: 75,
      wx_key: 'icon_smiley_93',
      remark: '[发抖]'
    },
    {
      code: '/:<O>',
      offset: 76,
      wx_key: 'icon_smiley_94',
      remark: '[怄火]'
    },
    {
      code: '/:circle',
      offset: 77,
      wx_key: 'icon_smiley_95',
      remark: '[转圈]'
    },
    { code: '😄', offset: 78, wx_key: 'icon_emoji_ios_0', remark: '😄' },
    { code: '😷', offset: 79, wx_key: 'icon_emoji_ios_1', remark: '😷' },
    { code: '😂', offset: 80, wx_key: 'icon_emoji_ios_2', remark: '😂' },
    { code: '😝', offset: 81, wx_key: 'icon_emoji_ios_3', remark: '😝' },
    { code: '😳', offset: 82, wx_key: 'icon_emoji_ios_4', remark: '😳' },
    { code: '😱', offset: 83, wx_key: 'icon_emoji_ios_5', remark: '😱' },
    { code: '😔', offset: 84, wx_key: 'icon_emoji_ios_6', remark: '😔' },
    { code: '😒', offset: 85, wx_key: 'icon_emoji_ios_7', remark: '😒' },
    {
      code: '[Hey]',
      offset: 86,
      wx_key: 'icon_emoji_wx_4',
      remark: '[嘿哈]'
    },
    {
      code: '[Facepalm]',
      offset: 87,
      wx_key: 'icon_emoji_wx_5',
      remark: '[捂脸]'
    },
    {
      code: '[Smirk]',
      offset: 88,
      wx_key: 'icon_emoji_wx_2',
      remark: '[奸笑]'
    },
    {
      code: '[Smart]',
      offset: 89,
      wx_key: 'icon_emoji_wx_6',
      remark: '[机智]'
    },
    {
      code: '[Concerned]',
      offset: 90,
      wx_key: 'icon_emoji_wx_12',
      remark: '[皱眉]'
    },
    {
      code: '[Yeah!]',
      offset: 91,
      wx_key: 'icon_emoji_wx_11',
      remark: '[耶]'
    },
    { code: '👻', offset: 92, wx_key: 'icon_emoji_ios_8', remark: '👻' },
    { code: '🙏', offset: 93, wx_key: 'icon_emoji_ios_9', remark: '🙏' },
    { code: '💪', offset: 94, wx_key: 'icon_emoji_ios_10', remark: '💪' },
    { code: '🎉', offset: 95, wx_key: 'icon_emoji_ios_11', remark: '🎉' },
    {
      code: '🎁',
      offset: 96,
      wx_key: 'icon_emoji_ios_12',
      remark: '[礼物]'
    },
    {
      code: '[Packet]',
      offset: 97,
      wx_key: 'icon_emoji_wx_9',
      remark: '[红包]'
    }
  ];
}
