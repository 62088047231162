export default function getEmotionsV2() {
  return [
    { code: '[微笑]', offset: 0, wx_key: 'icon_smiley_0' },
    { code: '[撇嘴]', offset: 1, wx_key: 'icon_smiley_1' },
    { code: '[色]', offset: 2, wx_key: 'icon_smiley_2' },
    { code: '[发呆]', offset: 3, wx_key: 'icon_smiley_3' },
    { code: '[得意]', offset: 4, wx_key: 'icon_smiley_4' },
    { code: '[流泪]', offset: 5, wx_key: 'icon_smiley_5' },
    { code: '[害羞]', offset: 6, wx_key: 'icon_smiley_6' },
    { code: '[闭嘴]', offset: 7, wx_key: 'icon_smiley_7' },
    { code: '[睡]', offset: 8, wx_key: 'icon_smiley_8' },
    { code: '[大哭]', offset: 9, wx_key: 'icon_smiley_9' },
    { code: '[尴尬]', offset: 10, wx_key: 'icon_smiley_10' },
    { code: '[发怒]', offset: 11, wx_key: 'icon_smiley_11' },
    { code: '[调皮]', offset: 12, wx_key: 'icon_smiley_12' },
    { code: '[呲牙]', offset: 13, wx_key: 'icon_smiley_13' },
    { code: '[惊讶]', offset: 14, wx_key: 'icon_smiley_14' },
    { code: '[难过]', offset: 15, wx_key: 'icon_smiley_15' },
    { code: '[冷汗]', offset: 16, wx_key: 'icon_smiley_17' },
    { code: '[抓狂]', offset: 17, wx_key: 'icon_smiley_18' },
    { code: '[吐]', offset: 18, wx_key: 'icon_smiley_19' },
    { code: '[偷笑]', offset: 19, wx_key: 'icon_smiley_20' },
    { code: '[愉快]', offset: 20, wx_key: 'icon_smiley_21' },
    { code: '[白眼]', offset: 21, wx_key: 'icon_smiley_22' },
    { code: '[傲慢]', offset: 22, wx_key: 'icon_smiley_23' },
    { code: '[困]', offset: 23, wx_key: 'icon_smiley_25' },
    { code: '[惊恐]', offset: 24, wx_key: 'icon_smiley_26' },
    { code: '[流汗]', offset: 25, wx_key: 'icon_smiley_27' },
    { code: '[憨笑]', offset: 26, wx_key: 'icon_smiley_28' },
    { code: '[悠闲]', offset: 27, wx_key: 'icon_smiley_29' },
    { code: '[奋斗]', offset: 28, wx_key: 'icon_smiley_30' },
    { code: '[咒骂]', offset: 29, wx_key: 'icon_smiley_31' },
    { code: '[疑问]', offset: 30, wx_key: 'icon_smiley_32' },
    { code: '[嘘]', offset: 31, wx_key: 'icon_smiley_33' },
    { code: '[晕]', offset: 32, wx_key: 'icon_smiley_34' },
    { code: '[衰]', offset: 33, wx_key: 'icon_smiley_36' },
    { code: '[骷髅]', offset: 34, wx_key: 'icon_smiley_37' },
    { code: '[敲打]', offset: 35, wx_key: 'icon_smiley_38' },
    { code: '[再见]', offset: 36, wx_key: 'icon_smiley_39' },
    { code: '[擦汗]', offset: 37, wx_key: 'icon_smiley_40' },
    { code: '[抠鼻]', offset: 38, wx_key: 'icon_smiley_41' },
    { code: '[鼓掌]', offset: 39, wx_key: 'icon_smiley_42' },
    { code: '[坏笑]', offset: 40, wx_key: 'icon_smiley_44' },
    { code: '[左哼哼]', offset: 41, wx_key: 'icon_smiley_45' },
    { code: '[右哼哼]', offset: 42, wx_key: 'icon_smiley_46' },
    { code: '[哈欠]', offset: 43, wx_key: 'icon_smiley_47' },
    { code: '[鄙视]', offset: 44, wx_key: 'icon_smiley_48' },
    { code: '[委屈]', offset: 45, wx_key: 'icon_smiley_49' },
    { code: '[快哭了]', offset: 46, wx_key: 'icon_smiley_50' },
    { code: '[阴险]', offset: 47, wx_key: 'icon_smiley_51' },
    { code: '[亲亲]', offset: 48, wx_key: 'icon_smiley_52' },
    { code: '[可怜]', offset: 49, wx_key: 'icon_smiley_54' },
    { code: '[菜刀]', offset: 50, wx_key: 'icon_smiley_55' },
    { code: '[西瓜]', offset: 51, wx_key: 'icon_smiley_56' },
    { code: '[啤酒]', offset: 52, wx_key: 'icon_smiley_57' },
    { code: '[咖啡]', offset: 53, wx_key: 'icon_smiley_60' },
    { code: '[猪头]', offset: 54, wx_key: 'icon_smiley_62' },
    { code: '[玫瑰]', offset: 55, wx_key: 'icon_smiley_63' },
    { code: '[凋谢]', offset: 56, wx_key: 'icon_smiley_64' },
    { code: '[嘴唇]', offset: 57, wx_key: 'icon_smiley_65' },
    { code: '[爱心]', offset: 58, wx_key: 'icon_smiley_66' },
    { code: '[心碎]', offset: 59, wx_key: 'icon_smiley_67' },
    { code: '[蛋糕]', offset: 60, wx_key: 'icon_smiley_68' },
    { code: '[炸弹]', offset: 61, wx_key: 'icon_smiley_70' },
    { code: '[便便]', offset: 62, wx_key: 'icon_smiley_74' },
    { code: '[月亮]', offset: 63, wx_key: 'icon_smiley_75' },
    { code: '[太阳]', offset: 64, wx_key: 'icon_smiley_76' },
    { code: '[拥抱]', offset: 65, wx_key: 'icon_smiley_78' },
    { code: '[强]', offset: 66, wx_key: 'icon_smiley_79' },
    { code: '[弱]', offset: 67, wx_key: 'icon_smiley_80' },
    { code: '[握手]', offset: 68, wx_key: 'icon_smiley_81' },
    { code: '[胜利]', offset: 69, wx_key: 'icon_smiley_82' },
    { code: '[抱拳]', offset: 70, wx_key: 'icon_smiley_83' },
    { code: '[勾引]', offset: 71, wx_key: 'icon_smiley_84' },
    { code: '[拳头]', offset: 72, wx_key: 'icon_smiley_85' },
    { code: '[OK]', offset: 73, wx_key: 'icon_smiley_89' },
    { code: '[跳跳]', offset: 74, wx_key: 'icon_smiley_92' },
    { code: '[发抖]', offset: 75, wx_key: 'icon_smiley_93' },
    { code: '[怄火]', offset: 76, wx_key: 'icon_smiley_94' },
    { code: '[转圈]', offset: 77, wx_key: 'icon_smiley_95' },
    { code: '😄', offset: 78, wx_key: 'icon_emoji_ios_0' },
    { code: '😷', offset: 79, wx_key: 'icon_emoji_ios_1' },
    { code: '😂', offset: 80, wx_key: 'icon_emoji_ios_2' },
    { code: '😝', offset: 81, wx_key: 'icon_emoji_ios_3' },
    { code: '😳', offset: 82, wx_key: 'icon_emoji_ios_4' },
    { code: '😱', offset: 83, wx_key: 'icon_emoji_ios_5' },
    { code: '😔', offset: 84, wx_key: 'icon_emoji_ios_6' },
    { code: '😒', offset: 85, wx_key: 'icon_emoji_ios_7' },
    { code: '[嘿哈]', offset: 86, wx_key: 'icon_emoji_wx_4' },
    { code: '[捂脸]', offset: 87, wx_key: 'icon_emoji_wx_5' },
    { code: '[奸笑]', offset: 88, wx_key: 'icon_emoji_wx_2' },
    { code: '[机智]', offset: 89, wx_key: 'icon_emoji_wx_6' },
    { code: '[皱眉]', offset: 90, wx_key: 'icon_emoji_wx_12' },
    { code: '[耶]', offset: 91, wx_key: 'icon_emoji_wx_11' },
    { code: '👻', offset: 92, wx_key: 'icon_emoji_ios_8' },
    { code: '🙏', offset: 93, wx_key: 'icon_emoji_ios_9' },
    { code: '💪', offset: 94, wx_key: 'icon_emoji_ios_10' },
    { code: '🎉', offset: 95, wx_key: 'icon_emoji_ios_11' },
    { code: '[礼物]', offset: 96, wx_key: 'icon_emoji_ios_12' },
    { code: '[红包]', offset: 97, wx_key: 'icon_emoji_wx_9' },
    { code: '[鸡]', offset: 98, wx_key: 'icon_emoji_wx_14' }
  ];
}
