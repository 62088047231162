import { login, logout, loginWithCode } from '@/api/login';
import { countStatisticsHot } from '@/api/car/car';
import {
  setUserInfo,
  removeUserInfo,
  getDevelopCustomerServiceParams
} from '@/common/localStorage/user';
import { setItem, removeItem } from '@/utils/cookie';
import {
  customerServiceSupplementDevCs,
  getAuthenticateInfo
} from '@/api/step';
import { MessageBox } from 'element-ui';
import router from '@/router';
import md5 from 'js-md5';
import scReporter from '@/utils/sc_reporter';
import { getBasicInfo } from '@/api/shop';
const user = {
  state: {
    userInfo: null,
    unfinishedOrderNum: 0,
    carNumber: 0,
    approveStatus: null,
    flagAuth: true // 防止GetAuthenticateInfo弹窗 重复调用
  },

  mutations: {
    SET_USER: (state, user) => {
      state.userInfo = user;
    },
    // 初始化购物车数量
    SET_CARNUMBER: (state, data) => {
      state.carNumber = data;
    },
    // 购物车数量变化
    UPDATE_SET_CARNUMBER: (state, data) => {
      state.carNumber += data;
    },
    // 初始收藏数量
    SET_UNFINISHEDORDERNUM: (state, data) => {
      state.unfinishedOrderNum = data;
    },

    // 修改分销商认证状态
    SET_APPROVE: (state, data) => {
      state.approveStatus = data;
    }
  },
  actions: {
    // 获取分销商认证状态
    GetAuthenticateInfo({ state, commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getAuthenticateInfo()
          .then(res => {
            const { data = null } = res;

            commit('SET_APPROVE', data);
            setItem('aS', data);
            const { logoutStatus } = data || {};
            if (
              (logoutStatus === 'LOGOUT_WAIT_AUDIT' ||
                logoutStatus === 'LOGOUT_PASS') &&
              state.flagAuth
            ) {
              state.flagAuth = false;
              MessageBox.alert('账号注销中或已注销', '系统提示', {
                confirmButtonText: '确定',
                callback: () => {
                  state.flagAuth = true;
                  dispatch('LogOut').then(() => {
                    const { history } = router;
                    if (history) {
                      const { fullPath } = history.current;
                      const redirect =
                        fullPath === '/syoung/user/shopList'
                          ? ''
                          : `?redirect=${fullPath}`; // 首页重定向 不要redirect
                      const url = `/syoung/login${redirect}`;
                      location.href = url;
                    }
                  });
                }
              });

              return reject({});
            }
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 密码登录
    Login({ commit, dispatch }, userInfo) {
      const username = userInfo.username.trim();
      return new Promise((resolve, reject) => {
        login(username, md5(userInfo.password))
          .then(response => {
            const data = response.data;
            commit('SET_USER', data);
            setUserInfo(data);
            scReporter.login(data.distributorId);
            dispatch(
              'bindDevelopCustomerService',
              getDevelopCustomerServiceParams()
            );
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 短信验证码登录
    LoginWithCode({ commit, dispatch }, userInfo) {
      const zz = /^[0-9]*$/;
      const username = userInfo.username.trim();
      const verificationCode = userInfo.verificationCode.trim();
      if (verificationCode.length !== 4 || !zz.test(verificationCode)) {
        return Promise.reject({ msg: '请输入正确的验证码' });
      }
      return new Promise((resolve, reject) => {
        loginWithCode(username, verificationCode)
          .then(response => {
            const data = response.data;
            commit('SET_USER', data);
            setUserInfo(data);
            scReporter.login(data.distributorId);
            dispatch(
              'bindDevelopCustomerService',
              getDevelopCustomerServiceParams()
            );
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 登出
    LogOut({ commit, dispatch }) {
      return logout()
        .then(() => {
          commit('SET_USER', null);
          commit('SET_APPROVE', null);
          commit('SET_CARNUMBER', 0);
          commit('SET_UNFINISHEDORDERNUM', 0);
          removeUserInfo();
          removeItem('aS');
          // 客服启用游客模式
          commit('chat/SET_IS_TOURISTS');
        })
        .then(() => {
          dispatch('chat/updateUp');
        });
    },
    // 前端登出 适用于登录状态失效
    FedLogOut({ commit }) {
      return new Promise(rs => {
        commit('SET_USER', null);
        commit('SET_APPROVE', null);
        commit('SET_CARNUMBER', 0);
        commit('SET_UNFINISHEDORDERNUM', 0);
        removeUserInfo();
        removeItem('aS');
        // 客服启用游客模式
        commit('chat/SET_IS_TOURISTS');
        rs();
      });
    },
    // 小红点数据-购物车、我的收藏数量
    getCountStatisticsHot({ commit }) {
      return countStatisticsHot().then(res => {
        commit('SET_CARNUMBER', res.data.shoppingCartNum);
        commit('SET_UNFINISHEDORDERNUM', res.data.unfinishedOrderNum);
      });
    },
    // 绑定拓展顾问操作
    bindDevelopCustomerService({ commit, state }, params) {
      // 判断参数及是否已登录
      if (!params || !Object.keys(params).length || !state.userInfo) {
        return;
      }
      const { developCSId = '' } = params;
      if (!developCSId) {
        return;
      }
      // 临时码未失效或者是永久码都需要进行绑定操作
      getBasicInfo().then(res => {
        const { developCustomerServiceId = '' } = res.data?.distributorVO;
        // 已绑定拓展顾问
        if (developCustomerServiceId) {
          console.log('>>>>>> 当前分销商已存在绑定拓展顾问');
          return;
        }
        //  进行绑定拓展顾问操作
        customerServiceSupplementDevCs(developCSId)
          .then(() => {
            console.log('>>>>>> 绑定拓展顾问绑定成功');
          })
          .catch(error => {
            console.log('>>>>>> 绑定拓展顾问绑定失败', error);
          });
      });
    }
  }
};

export default user;
