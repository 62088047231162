// set function parseTime,formatTime to filter
import round from 'lodash/round';
export { parseTime, formatTime, parseDefaultTime } from '@/utils';
export * from './user';
export function parsePercent(percent) {
  return round(percent * 100, 2);
}
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + 's';
}

export function filterMap(key, map) {
  return map[key] || '';
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute');
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour');
  } else {
    return pluralize(~~(between / 86400), ' day');
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
        si[i].symbol
      );
    }
  }
  return num.toString();
}

export function toThousandslsFilter(num) {
  if (!num) return '0';
  const res = num.toString().replace(/\d+/, function(n) { // 先提取整数部分
  return n.replace(/(\d)(?=(\d{3})+$)/g, function($1) {
     return $1 + ',';
   });
  });
  return res;
}

//- 小写数字转换成大写, 只处理到[0 ~ 99]
export function numberConvertToUppercase(num) {
  num = Number(num);
  const upperCaseNumber = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '百',
    '千',
    '万',
    '亿',
  ];
  const length = String(num).length;
  if (length === 1) {
    return upperCaseNumber[num];
  } else if (length === 2) {
    if (num === 10) {
      return upperCaseNumber[num];
    } else if (num > 10 && num < 20) {
      return '十' + upperCaseNumber[String(num).charAt(1)];
    } else {
      return (
        upperCaseNumber[String(num).charAt(0)] +
        '十' +
        upperCaseNumber[String(num).charAt(1)].replace('零', '')
      );
    }
  }
}

// 不足小数点后的零 1 -> 1.00
export function fillDecimalNumberZero(num, number = 2) {
  /^(\-)*(\d+)(\.(\d*))?$/.test(num);
  return RegExp.$1 + RegExp.$2 + '.' + RegExp.$4.padEnd(number, 0);
}
