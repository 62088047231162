<template>
  <div class="chat-time">
    <span class="chat-item">{{ date | filterDate }}</span>
  </div>
</template>

<script>
import parseDate from '@/utils/parseDate';
export default {
  name: 'ChatTime',
  props: {
    date: Number,
  },
  filters: {
    filterDate(date) {
      return parseDate(date);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.chat-time {
  text-align: center;
  margin: 25px 0;
}
.chat-item {
  border-radius: 6px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 3px 6px;
  font-size: 10px;
}
</style>
