import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import eventReporter from '@/utils/event-reporter';
import { authForceLogout } from '@/api/login';

Vue.use(Router);

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;
// push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};
// replace
Router.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  }
  return originalReplace.call(this, location).catch(err => err);
};
/* Layout */
import Layout from '../views/layout/Layout';
/**

* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)

* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length

* if not set alwaysShow, only more than one route under the children

* it will becomes nested mode, otherwise not show the root menu

* redirect: noredirect           if `redirect:noredirect` will no redirct in the breadcrumb

* name:'router-name'             the name is used by <keep-alive> (must set!!!)

* meta : {
  title: 'title'               the name show in submenu and breadcrumb (recommend set)
  icon: 'svg-name'             the icon show in the sidebar,
  }
  **/


export const constantRouterMap = [
  // 首页
  {
    path: '/',
    component: () => import('@/views/website/index'),
    hidden: true,
    name: '/index'
  },
  // 协议
  {
    path: '/agreement',
    component: () => import('@/views/agreement/index'),
    hidden: true,
    name: '/agreement/index'
  },
  // 联系开发者
  {
    path: '/agreement/contact',
    component: () => import('@/views/agreement/contact'),
    hidden: true,
    name: '/agreement/index'
  },
  // 国货品牌
  {
    path: '/brand-pavilion-self-support',
    component: () => import('@/views/website/brand-pavilion-self-support'),
    hidden: true,
    name: '/brand-pavilion-self-support',
    meta: {
      title: '国货品牌'
    }
  },
  // 国际品牌
  {
    path: '/brand-pavilion-internationalization',
    component: () => import('@/views/website/brand-pavilion-internationalization'),
    hidden: true,
    name: '/brand-pavilion-internationalization',
    meta: {
      title: '国际品牌'
    }
  },
  // 关于我们
  {
    path: '/about',
    component: () => import('@/views/website/about'),
    hidden: true,
    name: '/about',
    meta: {
      title: '关于我们'
    }
  },
  // 等级权益
  {
    path: '/rights',
    component: () => import('@/views/website/rights.vue'),
    hidden: true,
    name: '/rights',
    meta: {
      title: '权益中心'
    }
  },
  {
    path: '/syoung/login',
    component: () => import('@/views/login'),
    hidden: true,
    name: '/syoung/login',
    meta: {
      title: '登录'
    }
  },
  {
    path: '/syoung/introduce/:id',
    name: '/syoung/introduce/:id',
    component: () => import('@/views/introduce'),
    hidden: true,
  },
  {
    path: '/syoung/register',
    component: () => import('@/views/layout/BlankLayout'),
    redirect: '/syoung/register/',
    name: '/syoung/register',
    hidden: true,
    children: [
      {
        path: 'register/',
        component: () => import('@/views/register/index'),
        name: '/syoung/register/',
        meta: {
          title: '注册'
        }
      },
      {
        path: 'forgetPsd',
        component: () => import('@/views/register/forgetPsd'),
        name: '/syoung/register/forgetPsd',
        meta: {
          title: '忘记密码'
        }
      },
      {
        path: 'step',
        component: () => import('@/views/register/step'),
        hidden: true,
        name: '/syoung/register/step',
        meta: {
          title: '入驻流程'
        }
      }
    ]
  },
  {
    path: '/syoung/logout',
    component: null,
    hidden: true,
    name: '/syoung/logout',
    beforeEnter: (to, form, next) => {
      const redirect = to.query.redirect ? `?redirect=${to.query.redirect}` : '';
      store.dispatch('LogOut').finally(() => next(`/syoung/login${redirect}`));
    },
    meta: {
      title: '退出登录'
    }
  },
  {
    path: '/syoung/401',
    component: () => import('@/views/401'),
    hidden: true,
    name: '/syoung/401'
  },
  {
    path: '/syoung/404',
    component: () => import('@/views/404'),
    hidden: true,
    name: '/syoung/404'
  },
  {
    path: '/syoung',
    component: () => import('@/views/layout/BlankLayout'),
    redirect: '/syoung/shop/detail',
    name: '/syoung',
    hidden: true
  },
  // 店铺概况
  {
    path: '/syoung/shop',
    component: Layout,
    redirect: '/syoung/shop/detail',
    name: '/syoung/shop',
    sort: 1,
    meta: {
      title: '我的店铺',
      icon: 'dianpugaikuang'
    },
    children: [
      {
        path: 'detail',
        name: '/syoung/shop/detail',
        component: () => import('@/views/shop'),
        meta: {
          title: '我的店铺',
          icon: 'dianpugaikuang'
        }
      },
      {
        path: 'cash-deposit-list',
        name: '/syoung/shop/cash-deposit-list',
        component: () => import('@/views/shop/cash-deposit-list'),
        meta: {
          title: '保证金明细'
        },
        hidden: true
      },
      {
        path: 'account-balance-list',
        name: '/syoung/shop/account-balance-list',
        component: () => import('@/views/shop/account-balance-list'),
        meta: {
          title: '账户余额明细'
        },
        hidden: true
      },
      {
        path: 'rebate-list',
        name: '/syoung/shop/rebate-list',
        component: () => import('@/views/shop/rebate-list'),
        meta: {
          title: '返利明细'
        },
        hidden: true
      },
      {
        path: 'disable',
        name: '/syoung/shop/disable',
        component: () => import('@/views/disable'),
        meta: {
          title: '冻结',
        },
        hidden: true
      },
      {
        path: 'transfer-account-info',
        name: '/syoung/shop/transfer-account-info',
        component: () => import('@/views/pay/transfer-account-info'),
        meta: {
          title: '线下转账充值',
        },
        hidden: true
      },
      {
        path: 'transfer-account-list',
        name: '/syoung/shop/transfer-account-list',
        component: () => import('@/views/pay/transfer-account-list'),
        meta: {
          title: '转账充值记录',
        },
        hidden: true
      },
      {
        path: 'contract-info',
        name: '/syoung/shop/contract-info',
        component: () => import('@/views/subject-information/contract-info'),
        meta: {
          title: '新增主体信息',
        },
        hidden: true
      },
      {
        path: 'view-subject-information/:id',
        name: '/syoung/shop/view-subject-information',
        component: () => import('@/views/subject-information/view-subject-information'),
        meta: {
          title: '查看主体信息',
        },
        hidden: true
      },
      {
        path: 'improve-information',
        name: '/syoung/shop/improve-information',
        component: () => import('@/views/subject-information/improve-information'),
        meta: {
          title: '完善主体信息',
        },
        hidden: true
      },
      {
        path: 'cash-out',
        name: '/syoung/shop/cash-out',
        component: () => import('@/views/cash-out/index'),
        meta: {
          title: '退款申请',
        },
        hidden: true
      },
      {
        path: 'cash-out-list',
        name: '/syoung/shop/cash-out/cash-out-list',
        component: () => import('@/views/cash-out/list'),
        meta: {
          title: '退款申请记录',
        },
        hidden: true
      },
    ]
  },
  // 商品管理
  {
    path: '/syoung/commodity',
    component: Layout,
    redirect: '/syoung/commodity/list',
    name: '/syoung/commodity',
    sort: 2,
    meta: {
      title: '商品采购',
      icon: 'shangpin'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/commodity/list',
        component: () => import('@/views/commodity/list'),
        meta: {
          title: '商品采购',
          icon: 'shangpin'
        }
      },
      {
        path: '/syoung/commodity/goods-detail/:id',
        name: '/syoung/commodity/goods-detail/:id',
        component: () => import('@/views/commodity/goods-detail'),
        meta: {
          title: '商品详情'
        },
        hidden: true,
      },
    ]
  },
  // 样品领用
  {
    path: '/syoung/sample-list',
    component: Layout,
    redirect: '/syoung/sample-list/list',
    name: '/syoung/sample-list',
    sort: 2,
    meta: {
      title: '样品领用',
      icon: 'shangpin'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/sample-list/list',
        component: () => import('@/views/sample-list/list'),
        meta: {
          title: '样品领用',
          icon: 'shangpin'
        }
      }
    ]
  },
    // 推品清单
    {
      path: '/syoung/recommended-list',
      component: Layout,
      redirect: '/syoung/recommended-list/index',
      name: '/syoung/recommended-list',
      sort: 3,
      meta: {
        title: '推品清单',
        icon: 'shangpin'
      },
      children: [
        {
          path: '/syoung/recommended-list/index',
          name: '/syoung/recommended-list/index',
          component: () => import('@/views/recommended-list/index'),
          meta: {
            title: '推品清单 - 列表',
            icon: 'shangpin'
          },
        },
        {
          path: 'detail/:id',
          name: '/syoung/recommended-list/detail/:id',
          component: () => import('@/views/recommended-list/detail'),
          meta: {
            title: '推品清单 - 详情'
          },
          hidden: true
        },
      ]
    },
  // 订单管理
  {
    path: '/syoung/order',
    component: Layout,
    redirect: '/syoung/order/inquiry/list',
    name: '/syoung/order',
    sort: 4,
    meta: {
      title: '订单管理',
      icon: 'dingdan'
    },
    children: [
      {
        path: 'inquiry/list',
        name: '/syoung/order/inquiry/list',
        component: () => import('@/views/order/inquiry/list'),
        meta: {
          title: '订单查询'
        }
      },
      {
        path: 'refund/list',
        name: '/syoung/order/refund/list',
        component: () => import('@/views/order/refund/list'),
        meta: {
          title: '退款订单'
        }
      },
      {
        path: 'inquiry/detail/:id',
        name: '/syoung/order/inquiry/detail/:id',
        component: () => import('@/views/order/inquiry/detail'),
        meta: {
          title: '订单详情'
        },
        hidden: true
      },
      {
        path: 'refund/detail/:id',
        name: '/syoung/order/refund/detail/:id',
        component: () => import('@/views/order/refund/detail'),
        meta: {
          title: '退款详情'
        },
        hidden: true
      },
      {
        path: 'inquiry/package/:id',
        name: '/syoung/order/inquiry/package/:id',
        component: () => import('@/views/order/inquiry/package'),
        meta: {
          title: '包裹信息'
        },
        hidden: true
      },
      {
        path: 'refund/delivery_status/:companyCode/:deliveryNo',
        name: '/syoung/order/refund/delivery_status/:companyCode/:deliveryNo',
        component: () => import('@/views/order/refund/delivery_status'),
        meta: {
          title: '物流状态'
        },
        hidden: true
      }
    ]
  },
  // 一件代发
  {
    path: '/syoung/drop-shipping-order',
    component: Layout,
    redirect: '/syoung/drop-shipping-order/list',
    name: '/syoung/drop-shipping-order',
    sort: 5,
    meta: {
      title: '一件代发',
      icon: 'qiche'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/drop-shipping-order/list',
        component: () => import('@/views/drop-shipping-order/list'),
        meta: {
          title: '大贸一件代发'
        }
      },
      {
        path: 'list-haitao',
        name: '/syoung/drop-shipping-order/list-haitao',
        hidden: true,
        component: () => import('@/views/drop-shipping-order/list-haitao'),
        meta: {
          title: '海淘一件代发'
        }
      }
    ]
  },
  // 样品领用记录
  {
    path: '/syoung/sample-receipt-record',
    component: Layout,
    redirect: '/syoung/sample-receipt-record/list',
    name: '/syoung/sample-receipt-record',
    sort: 6,
    meta: {
      title: '样品领用记录',
      icon: 'shangpin'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/sample-receipt-record/list',
        component: () => import('@/views/sample-receipt-record/list'),
        meta: {
          title: '样品领用记录',
          icon: 'shangpin'
        }
      }
    ]
  },
  // 个人信息
  {
    path: '/syoung/information',
    component: Layout,
    //redirect: '/syoung/address/list',
    name: '/syoung/information',
    sort: 8,
    meta: {
      title: '个人信息',
      icon: 'shangpin'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/information/list',
        component: () => import('@/views/address/index'),
        meta: {
          title: '地址管理'
        }
      },
      {
        path: 'modifyPass',
        component: () => import('@/views/login/modify'),
        name: '/syoung/information/modifyPass',
        meta: {
          title: '登录密码管理'
        }
      },
      {
        path: 'change-pay-password',
        component: () => import('@/views/login/change-pay-password'),
        name: '/syoung/information/change-pay-password',
        meta: {
          title: '支付密码管理'
        }
      },
      {
        path: 'modify-mobile',
        component: () => import('@/views/login/modify-mobile'),
        name: '/syoung/information/modify-mobile',
        meta: {
          title: '更换登录手机号'
        }
      },
      {
        hidden: true,
        path: 'modify-mobile-change',
        component: () => import('@/views/login/modify-mobile-change'),
        name: '/syoung/information/modify-mobile-change',
        meta: {
          title: '更换登录手机号'
        }
      },
    ]
  },
  // 采购车
  {
    path: '/syoung/car',
    component: Layout,
    redirect: '/syoung/car/list',
    name: '/syoung/car',
    sort: 3,
    meta: {
      title: '采购车',
      icon: 'gouwuche'
    },
    children: [
      {
        path: 'list',
        name: '/syoung/car/list',
        component: () => import('@/views/car/index'),
        meta: {
          title: '采购车'
        }
      }
    ]
  },
  // 商品收藏
  {
    path: '/syoung/goods-collect-nav',
    component: Layout,
    redirect: '/syoung/goods-collect',
    name: '/syoung/goods-collect-nav',
    sort: 7,
    meta: {
      title: '商品收藏',
      icon: 'gouwuche'
    },
    children: [
      {
        path: '/syoung/goods-collect',
        component: () => import('@/views/commodity/goods-collect'),
        name: '/syoung/goods-collect',
        sort: 7,
        meta: {
          title: '商品收藏',
        },
      },
    ]
  },
  // 我的账单
  {
    path: '/syoung/sale-credit',
    component: Layout,
    redirect: '/syoung/sale-credit/history-list',
    name: '/syoung/sale-credit',
    hidden: true,
    sort: 3,
    meta: {
      title: '我的账单',
      icon: 'gouwuche'
    },
    children: [
      {
        path: 'detail/:id',
        name: '/syoung/sale-credit/detail/:id',
        component: () => import('@/views/sale-credit/detail'),
        meta: {
          title: '我的账单'
        }
      },
      {
        path: 'history-list',
        name: '/syoung/sale-credit/history-list',
        component: () => import('@/views/sale-credit/history-list'),
        meta: {
          title: '历史账单'
        }
      },
      {
        path: 'sale-credit-repayment',
        name: '/syoung/sale-credit/sale-credit-repayment',
        component: () => import('@/views/sale-credit/sale-credit-repayment'),
        meta: {
          title: '还款'
        }
      }
    ]
  },
  // 确认订单
  {
    path: '/syoung/checkout',
    component: Layout,
    redirect: '/syoung/checkout/list',
    name: '/syoung/checkout',
    meta: {
      title: '确认订单'
    },
    hidden: true,
    children: [
      {
        path: 'list',
        name: '/syoung/checkout/list',
        component: () => import('@/views/checkout/index'),
        meta: {
          title: '确认订单'
        }
      }
    ]
  },
  // 支付
  {
    path: '/syoung/pay',
    component: Layout,
    redirect: '/syoung/pay/list',
    name: '/syoung/pay',
    meta: {
      title: '支付'
    },
    hidden: true,
    children: [
      {
        path: 'list',
        name: '/syoung/pay/list',
        component: () => import('@/views/pay/index'),
        meta: {
          title: '支付'
        }
      }
    ]
  },
  // 开票管理
  {
    path: '/syoung/invoice',
    component: Layout,
    redirect: '/syoung/invoice/invoice-record',
    name: '/syoung/invoice',
    sort: 6,
    meta: {
      title: '开票管理',
      icon: 'fapiaoguanli2'
    },
    children: [
      {
        path: 'invoice-record',
        name: '/syoung/invoice/invoice-record',
        component: () => import('@/views/invoice/invoice-record'),
        meta: {
          title: '开票记录'
        }
      },
      {
        path: 'invoice-order',
        name: '/syoung/invoice/invoice-order',
        component: () => import('@/views/invoice/invoice-order'),
        meta: {
          title: '可开票订单'
        }
      },
      {
        path: 'invoice-header',
        name: '/syoung/invoice/invoice-header',
        component: () => import('@/views/invoice/invoice-header'),
        meta: {
          title: '填写发票抬头'
        },
        hidden: true
      },
      {
        path: 'invoice-confirm-header',
        name: '/syoung/invoice/invoice-confirm-header',
        component: () => import('@/views/invoice/invoice-confirm-header'),
        meta: {
          title: '确认开票信息'
        },
        hidden: true
      },
      {
        path: 'invoice-have-header',
        name: '/syoung/invoice/invoice-have-header',
        component: () => import('@/views/invoice/invoice-have-header'),
        meta: {
          title: '确认开票信息'
        },
        hidden: true
      }
    ]
  },
  {
    path: '/syoung/*',
    redirect: '/syoung/404',
    hidden: true,
    name: '/syoung/*'
  }
];
const router = new Router({
  mode: 'history', // 后端支持可开
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRouterMap
});

router.afterEach((to, from) => {
  const { shopName = '', userToken } = store.getters.userInfo || {};
    window.watermark.init({
      watermark_txt: shopName ? `水羊直供-${shopName}` : '水羊直供'
    });
  // 主体迁移后需要强制用户退出登录
  if (userToken) {
    authForceLogout().then(res => {
      console.log('authForceLogout', res);
    });
  }
  eventReporter.utmTrack();
  if (to.meta.autoTrack !== false) {
    // 采集PageView事件
    eventReporter.trackPageView(to, from);
  }
});
export default router;

