<template>
  <div class="container">
    <div :class="$route.name === '/index' ? 'home top-bar' : 'top-bar'">
      <div class="top-bar__img-box top-bar__col">
        <img
          alt
          class="top-bar__logo"
          @click="goHome"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logoName_180_50.png"
        />
      </div>
      <div
        :class="
          showSearchBar ? 'top-bar__searching top-bar__col' : 'top-bar__col top-bar__search-box'
        "
        v-if="!showContacts"
      >
        <el-input
          placeholder="请输入商品名称"
          prefix-icon="el-icon-search"
          v-model="name"
          class="top-bar__search"
          @focus="showSearchBar = true"
        />
        <div @click="onSearch" class="top-bar__search-btn" v-if="showSearchBar">搜索</div>
      </div>
      <div class="top-bar__top-info top-bar__col" v-if="!showSearchBar && !showContacts">
        <div class="top-bar__login" v-if="$route.name !== '/syoung/login'">
          <div v-if="loginFlag">
            <!-- 审核已通过高 -->
            <span v-if="isCertification">{{ approveStatus ? approveStatus.shopName : '' }}</span>
            <!-- 审核中 -->
            <span v-else>信息认证中<span @click="certification" class="top-bar__logout">去查看</span></span>
            <!-- 退出登录 -->
            <span @click="logout" class="top-bar__logout">退出登录</span>
          </div>
        </div>
        <el-button type="primary" size="medium" icon="el-icon-shopping-bag-2" @click="jumpPurchase">去采购<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button type="primary"  size="medium" @click="jumpGradeRights">权益中心<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button size="medium" @click="contactUs">联系我们<i class="el-icon-arrow-down" style="margin-left: 4px" /></el-button>
      </div>
      <i
        class="el-icon-close top-bar__col top-bar__close-box"
        @click="close"
        v-if="showSearchBar || showContacts"
      ></i>
    </div>

    <div class="search-bar" v-if="showSearchBar">
      <div class="search-bar__items" v-for="(item, index) in categoryList" :key="index">
        <div class="search-bar__title">{{ item.name }}</div>
        <div class="search-bar__sub-items-box">
          <div
            class="search-bar__sub-items"
            v-for="(items, idx) in item.children"
            :key="index + '__' + idx"
          >
            <div @click="jumpTo(items)">{{ items.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="contacts" v-if="showContacts">
      <div class="contacts__help">
        <div class="contacts__help-title">需要帮助吗?</div>
        <div class="contacts__tips">您可以电话联系我们：</div>
        <div class="contacts__phone">客户服务中心 400 825 9920</div>
        <div class="contacts__time">周一至周五：8:00-23:00</div>
      </div>
      <div class="contacts__img-box">
        <div class="contacts__tips">其他联系方式：</div>
        <div class="contacts__other-box">
          <div class="contacts__wechat">
            <img
              src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/url-code-footer.png"
              class="img"
              alt=""
            />
            <div>微信小程序</div>
          </div>
          <div>
            <img
              src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/website-kefu-v2.png"
              class="img"
              alt=""
            />
            <div>企业微信</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { remove } from '@/utils/removeLocalStorage';
import { listAllForWebsite } from '@/api/website/home';
import eventReporter from '@/utils/event-reporter';
export default {
  name: 'TopBar',
  data() {
    return {
      showSearchBar: false,
      showContacts: false,
      name: '',
      codeShow: false,
      isCertification: false,
      categoryList: [],
    };
  },
  props: {},
  filters: {},

  mounted() {
    listAllForWebsite().then(res => {
      if (res.code === '0' && res.data) {
        this.categoryList = [...res.data];
      }
    });
  },
  computed: {
    ...mapGetters(['userInfo', 'approveStatus']),
    loginFlag() {
      if (this.userInfo) {
        this.GetAuthenticateInfo().then(res => {
          const approveStatus = this.approveStatus;
          this.isCertification =
            approveStatus instanceof Object &&
            (approveStatus.status === 'PASS' || approveStatus.status === 'DISABLE');
        });
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations('chat', ['CUSTOMER_DOT_SHOW']),
    ...mapActions(['GetAuthenticateInfo', 'LogOut']),
    ...mapActions('chat', ['shutDown']),
    jumpTo({ id }) {
      eventReporter.trackClick(this.$route, {
        name: 'homeCategory',
        aliasName: '品类',
        query: id,
        event_source: 'home_category',
      });

      this.showSearchBar = false;
      this.$router.push(`/syoung/commodity/list?commodityKindId=${id}`);
    },
    goLogin() {
      eventReporter.trackClick(this.$route, {
        name: 'homeGoLogin',
        aliasName: '去登录',
        event_source: 'home_go_login',
      });

      this.$router.push(`/syoung/login`);
    },
    goHome() {
      this.$router.push('/');
    },
    logout() {
      this.LogOut().then(() => {
        setTimeout(() => {
          this.CUSTOMER_DOT_SHOW(false);
          this.shutDown();
        }, 100);
        if (this.$route.fullPath !== '/') {
          this.$router.replace('/');
        }
        remove();
      });
    },
    certification() {
      this.$router.replace('/syoung/register/step');
    },
    close() {
      this.showContacts = false;
      this.showSearchBar = false;
    },
    // 联系我们
    contactUs() {
      eventReporter.trackClick(this.$route, {
        name: 'homeContactUs',
        aliasName: '联系我们',
        event_source: 'home_contact_us',
      });

      this.showContacts = true;
    },
    onSearch() {
      eventReporter.trackSearch(this.$route, {
        keyword: this.name,
      });
      this.showSearchBar = false;
      if (this.$route.name === '/syoung/commodity/list') {
        this.$root.$emit('onBusSearchGoods', this.name);
      } else {
        this.$router.push(`/syoung/commodity/list?name=${this.name}`);
      }
    },
    jumpPurchase() {
      eventReporter.trackClick(this.$route, {
        name: 'homeGoShopping',
        aliasName: '去采购',
        event_source: 'home_go_shopping',
      });
      this.$router.push('/syoung/commodity/list');
    },
    jumpGradeRights(){
      this.$router.push('/rights');
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
}

.top-bar {
  font-size: 0.7vw;
  color: #000;
  z-index: 1000;
  min-width: 1200px;
  height: 78px;
  line-height: 78px;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid #efefef;
  position: relative;
  letter-spacing: 1px;
  &__col {
    flex: 1;
    text-align: center;
    &.top-bar__img-box {
      text-align: left;
      display: flex;
      align-items: center;
    }
  }
  // &.home{
  //   background:  #fff;
  // }
  &.img-box {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 100px;
    cursor: pointer;
  }

  &__search-box {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 0;
    width: 296px;
    margin-left: -148px;
  }
  &__searching {
    width: 550px;
    height: 34px;
    line-height: 32px;
    background: #ffffff;
    border-radius: 1px;
    overflow: hidden;
    border: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-bar__search {
      width: 480px;

      /deep/ .el-input__inner {
        background: transparent;
      }
    }
  }
  &__search {
    color: #000;
    /deep/ .el-input__inner {
      background: #f6f6f6;
      border: none;
      border-radius: 1px;
    }

    /deep/ .el-icon-search {
      color: #000;
    }
  }

  &__search-btn {
    cursor: pointer;
    width: 53px;
    height: 24px;
    background: #000000;
    border-radius: 1px;
    text-align: center;
    line-height: 24px;
    font-size: 10px;
    color: #ffffff;
    margin-right: 5px;
  }

  &__contacts {
    cursor: pointer;
    margin-left: 2vw;
  }

  &__top-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  &__login {
    cursor: pointer;
    margin-right: 20px;
  }

  &__shop {
    cursor: pointer;
  }

  &__logout {
    color: #666;
    margin-left: 10px;
    cursor: pointer;
    border-bottom: 1px solid #666;
    &:hover{
      color: #AB0033;
      border-color: #AB0033;
    }
  }
  &__close-box {
    text-align: right;
  }
}

.search-bar {
  background: #fff;
  position: absolute;
  top: 78px;
  left: 0;
  width: 100%;
  height: 222px;
  display: flex;
  justify-content: center;
  padding: 30px 0 22px 0;
  border-bottom: 1px solid #efefef;
  z-index: 99999;

  &__items {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    line-height: 17px;
    width: 120px;
    margin-right: 50px;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__sub-items-box {
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__sub-items {
    font-size: 12px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
    line-height: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      color: #ab0033;
    }
  }
}

.contacts {
  background: #fff;
  position: absolute;
  top: 78px;
  left: 0;
  width: 100%;
  height: 282px;
  display: flex;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid #efefef;
  z-index: 99999;

  &__help-title {
    height: 63px;
    line-height: 29px;
    font-size: 21px;
    font-weight: 400;
    color: #000000;
  }

  &__phone {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
    line-height: 17px;
  }

  &__time {
    font-size: 11px;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
    margin-top: 20px;
  }

  &__tips {
    font-size: 11px;
    font-weight: 400;
    color: #000000;
    line-height: 16px;
    margin-bottom: 30px;
  }

  &__img-box {
    padding-top: 63px;
    margin-left: 153px;
  }

  &__other-box {
    display: flex;
    text-align: center;
    font-size: 9px;
    font-weight: 400;
    color: #000000;
    line-height: 13px;
  }

  &__wechat {
    margin-right: 34px;
  }

  .img {
    width: 100px;
    display: block;
    margin-bottom: 15px;
  }
}

.el-icon-close {
  font-size: 24px;
  color: #000;
  cursor: pointer;
  z-index: 999999;
}
</style>
