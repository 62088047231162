/**
 * 滚动区域是否触底检查
 * 例如：客户会话收到消息时，检查滚动区域是否触底，如果触底，则在收到消息后触发自动沉底，否则无事发生
 */
export default async function useScrollToBottomCheck(ctx, next) {
  const { getScrollElement } = ctx;
  const element = getScrollElement && getScrollElement();
  if (!element) {
    await next();
    return;
  }
  const { scrollHeight, scrollTop, clientHeight } = element;
  ctx.ignoreScrollState = scrollHeight - (scrollTop + clientHeight) > 20;
  await next();
}
