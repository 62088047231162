export default function composeMiddleware(...middlewares) {
  return (ctx = {}) => {
    function dispatch(idx) {
      const mid = middlewares[idx];
      if (!mid) {
        return Promise.resolve();
      }
      return mid(ctx, () => dispatch(idx + 1));
    }
    return dispatch(0);
  };
}
