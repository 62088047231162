<template>
  <div id="app" class="app" :class="{ 'is-mobile': isMobile }">
    <template v-if="!isMobile">
      <template
        v-if="
          $route.name !== '/agreement/index' &&
            $route.name !== '/agreement/contact'
        "
      >
        <TopBar />
        <RightSuspension v-if="rightSuspensionFlag" />
        <router-view />
        <declare />
        <Footer />
      </template>
      <router-view v-else />
    </template>
    <div class="app-guide" v-else>
      <img alt="水羊直供" class="app-guide__img" :src="appGuidePoster" />
      <div class="app-guide__btn" @click="getUrlScheme"></div>
    </div>
  </div>
</template>

<script>
import Declare from '@/components/Declare.vue';
import TopBar from '@/components/views/website/home-new/TopBar.vue';
import Footer from '@/components/views/website/home-new/Footer.vue';
import RightSuspension from './components/RightSuspension';
import { shortUrlGenerateWxUrlScheme } from '@/api/configure';
export default {
  name: 'Default',
  data() {
    return {
      isMobile: false,
      appGuidePoster: `https://oss.syounggroup.com/static/file/soyoung-zg/official-website/app-guide-poster.jpeg?t=${new Date().getTime()}`
    };
  },
  components: {
    TopBar,
    Declare,
    Footer,
    RightSuspension
  },
  created() {
    this.isMobile = this.getIsMobile();
  },
  mounted() {},
  computed: {
    rightSuspensionFlag() {
      const list = ['/syoung/login', '/syoung/register/step', '/index'];
      if (list.indexOf(this.$route.name) > -1) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    // 判断是 移动端
    getIsMobile() {
      const userAgentInfo = navigator.userAgent;
      const Agents = [
        'Android',
        'iPhone',
        'SymbianOS',
        'Windows Phone',
        'iPad',
        'iPod',
        'HarmonyOS'
      ];
      const getArr = Agents.filter(i => userAgentInfo.includes(i));
      return !!getArr.length;
    },
    // 打开微信小程序
    getUrlScheme() {
      const params = {
        path: '/pages/index',
        query: 'scene=useless',
        envVersion: 'release',
        expireInterval: 30,
        expireType: 0,
      };
      shortUrlGenerateWxUrlScheme(params).then(res => {
        const wxUrlScheme = res.data || '';
        if (wxUrlScheme) {
          window.location.href = wxUrlScheme;
        }
      });
    }
  }
};
</script>

<style lang="scss">
.app {
  min-width: 1380px;
}
.is-mobile {
  min-width: 100%;
}
.common-i-buy-global {
  padding: 2px 6px;
  font-style: normal;
  font-size: 12px;
  color: #fff;
  background: #ab0033;
  border-radius: 8px;
  vertical-align: bottom;
  margin-right: 5px;
}
.app-guide{
  width: 100%;
  min-height: 100vh;
  position: relative;
  &__img{
    width: 100%;
  }
  &__btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 300px;
  }
}
</style>
