import { withExtTenantIdRequest } from '@/utils/request';

// 获取购物车商品列表
export function listMyAll(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantShoppingCart/listMyAll',
    method: 'post',
    data
  });
}
// 商品移出购物车
export function removeByIds(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantShoppingCart/removeByIds',
    method: 'post',
    data
  });
}
// 修改购物车商品数量
export function updateCommodityQuantity(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantShoppingCart/updateCommodityQuantity',
    method: 'post',
    data
  });
}
// 小红点数据-购物车、我的收藏数量
export function countStatisticsHot(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantDistributor/countStatisticsHot',
    method: 'post',
    data
  });
}
