import 'babel-polyfill';
import Vue from 'vue';
import { Plugin } from 'vue-fragment';
import 'normalize.css/normalize.css'; // 重置样式

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // 中文

import '@/styles/index.scss'; // 全局 css

import VConsole from 'vconsole';
import { parseUrl } from '@/utils/query';

import App from './App';
import router from './router';
import store from './store';

import VueImageSwipe from 'vue-image-swipe';
import 'vue-image-swipe/dist/vue-image-swipe.css';
// 动态设置页面title
import VueWechatTitle from 'vue-wechat-title';
Vue.use(VueWechatTitle);

// 视频
const VueVideoPlayer = require('vue-video-player/dist/ssr');
const hls = require('videojs-contrib-hls');
Vue.use(hls);
Vue.use(VueVideoPlayer);

// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/ssr';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);

import '@/utils/permission'; // 权限控制
import * as filters from './filters'; // filter
import '@/utils/console';
import VueClipboard from 'vue-clipboard2'; // 全局复制
import eventReporter from '@/utils/event-reporter';
import monitor from '@/utils/monitor';
import GlobalComponents from '@/components/GlobalComponents/index.js';
const info = require('../package.json');
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
monitor.setAppVersion(info.version);
import MobileDetect from '@/utils/mobile-detect';
import '@/directives';
if (parseUrl(location.href).query.v === 'console') {
  new VConsole();
}
Vue.use(GlobalComponents);
Vue.use(Plugin);

Vue.use(VueClipboard);
Vue.use(ElementUI, {
  size: 'small', // set element-ui default size
  locale
});
Vue.use(VueImageSwipe);
import Mixin from '@/mixins'; // 全局混入
Vue.mixin(Mixin);
// 注入全局的filter
import scReporter from '@/utils/sc_reporter';
// 挂载到原型上方便调用
Vue.prototype.$scReporter = scReporter;
// 需要在 App 实例化之前调用 registerApp() 方法完成公共属性的注册，否则会导致部分数据采集不到注册的公共属性
scReporter.init();
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.config.productionTip = false;
Vue.config.keyCodes = {
  f11: 122
};
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
});
window.$vue = Vue;
window._store = store;
function getNetworkType () {
  const ua = navigator.userAgent;
  let networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
  networkStr = networkStr.toLowerCase().replace('nettype/', '');
  let networkType;
  switch (networkStr) {
    case 'wifi':
      networkType = 'wifi';
      break;
    case '4g':
      networkType = '4g';
      break;
    case '3g':
      networkType = '3g';
      break;
    case '3gnet':
      networkType = '3g';
      break;
    case '2g':
      networkType = '2g';
      break;
    default:
      networkType = '其他';
  }
  return networkType;
}

const userAgent = window.navigator.userAgent;
const md = new MobileDetect(userAgent);
let os = md.os();
let osVersion = '';
if (os === 'AndroidOS') {
  osVersion = md.version('Android');
  os = 'Android';
} else if (os === 'iOS') {
  osVersion = md.version('iPhone');
}
const networkType = getNetworkType();
eventReporter.registerPage({
  clientType: 'merchant_pc',
  shopId: 'soyoung-zg',
  screenWeight: screen.width,
  screenHeight: screen.height,
  os,
  osVersion,
  networkType,
  isWifi: networkType === 'wifi'
});
eventReporter.init();
store.dispatch('EstimateIsSupportWebp'); // 判断是否支持webp
new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: { App },
  data: {
    // Bus中央事件总线  空的实例放到根组件下，所有的子组件都能调用 用于非父子组件传值
    Bus: new Vue()
  }
});
