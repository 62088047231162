<template>
  <div class="emotion-wrap">
    <table @click.stop="onClickProxy" class="emotion-list" ref="emotion">
      <tbody>
        <tr :key="index" v-for="(rows, index) in tableList">
          <td :key="xidx" v-for="(row, xidx) in rows">
            <div
              :data-proxy-key="row.index"
              :style="{ backgroundPosition: row.backgroundPosition }"
              class="emotion-icon"
              v-if="row"
            >
              {{ row.code }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EmotionUtil from '@/helper/EmotionUtil';
import ceil from 'lodash/ceil';
import floor from 'lodash/floor';
import generatorEventProxy from '@/utils/generatorEventProxy';

const emotionUtil = new EmotionUtil();
export default {
  name: 'Emotions',
  data() {
    return {
      // 表情包列数
      cols: 8,
      emotions: Object.freeze(emotionUtil.emotions),
    };
  },
  methods: {
    onClickProxy: generatorEventProxy('emotion', function hanlde(proxyKey, e) {
      const emotion = this.emotions[proxyKey];
      this.$emit('commit', {
        emotion,
        element: e.target,
      });
    }),
  },
  computed: {
    tableList() {
      const size = this.cols;
      // 二维数组
      const list = Array.from(new Array(ceil(this.emotions.length / 8)), () =>
        new Array(size).fill(null)
      );
      this.emotions.forEach((item, index) => {
        const x = index % size;
        const y = floor(index / size);
        item.index = index;
        list[y][x] = item;
      });
      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.emotion-list {
  width: 100%;
  text-align: center;
  td {
    padding: 3px 0;
  }
}
.emotion-icon {
  width: 30px;
  height: 30px;
  font-size: 0;
  letter-spacing: 0;
  background-size: 100%;
  vertical-align: middle;
  display: inline-block;
  background-image: url('../assets/icon_emotion_panel.2x49d02c.png');
  cursor: pointer;
}
.emotion-wrap {
  height: 208px;
  overflow: auto;
}
</style>
