import uniqueId from 'lodash/uniqueId';
import pick from 'lodash/pick';
import chatMessageSendMaker from '@/helper/chatMessageSendMaker';
export default class ChatMessage {
  constructor(options) {
    this.$options = options;
    const {
      // 消息类型
      type,
      // 消息内容
      content,
      // 发送者ID
      sendUserId,
      // 消息ID
      id,
      // 本地标识
      uid = uniqueId('MSG_'),
      // 消息创建时间
      createDate,
      // 消息发送时间
      sendDate,
      // 是否是客服发送的 '0': 否, '1': 是
      isSendByCs,
      /**
       * 状态：
       * pending 发送中
       * success 发送成功
       * fail 失败 （失败需要手动重试）
       */
      status,
      // 图片上传中
      uploading = false,
      // 图片加载中
      loading = false,
      // 消息的错误提示
      errMsg = '',
      // 发送重试
      retryFunc,
      // H5消息载体 小程序 WX_MINIAPPS
      channelType,
      // 是否是游客模式
      isTourists
    } = options;
    this.sendDate = sendDate;
    this.loading = loading;
    this.uploading = uploading;
    this.isSendByCs = isSendByCs;
    this.type = type;
    this.content = content;
    this.uid = uid;
    this.id = id;
    this.status = status;
    this.sendUserId = sendUserId;
    this.createDate = createDate;
    this.errMsg = errMsg;
    this.retryFunc = retryFunc;
    this.channelType = channelType;
    this.isTourists = isTourists;
  }
  get body() {
    const { channelType, type, content, sendUserId, isTourists } = this;
    const data = {

      // H5消息载体：WX_MINIAPPS 或者 没有
      ...(channelType && { channelType }),
      // 消息类型
      msgType: type,
      // 消息内容
      msgContent: content
    };
    if (isTourists)data.anonId = sendUserId; // 游客模式需要发送id
    return data;
  }
  static createTextMsg(text) {
    return new ChatMessage({
      type: 'text',
      content: text,
      isSendByCs: '0'
    });
  }
  static createImgMsg(file) {
    return new ChatMessage({
      type: 'image',
      content: URL.createObjectURL(file),
      isSendByCs: '0'
    });
  }
  // 接收客服消息
  static withReceiveMessage(payload) {
    const { msgType, msgContent } = payload;
    return new ChatMessage({
      type: msgType,
      content: msgContent,
      isSendByCs: '1',
      ...pick(payload, ['id', 'sendUserId', 'createDate'])
    });
  }
  // 翻译isSendSuccess
  static isSendSuccess(status) {
    switch (status) {
      case '1':
        return 'success';
      case '0':
        return 'fail';
      default:
        return '';
    }
  }
  static sendMessageCreator(sendToMember, retrySendToMember) {
    return commit => message => {
      const setMessage = ChatMessage.setMessage(commit)(message);
      const setStatus = ChatMessage.setStatus(setMessage);
      const { sendMessageMaker, uploadImageMaker } = chatMessageSendMaker({
        setStatus,
        commit,
        chatMessage: message
      });
      return {
        sendMsg: sendMessageMaker(sendToMember, retrySendToMember),
        uploadImg: uploadImageMaker
      };
    };
  }
  static sendMsgActionCreator(sendToMember, retrySendToMember) {
    return (context, payload) => {
      const { commit } = context;
      context.sendMessageCreator = ChatMessage.sendMessageCreator(
        sendToMember,
        retrySendToMember
      )(commit);
      return callback => callback(context, payload);
    };
  }
  // 根据消息分页数据生成Messsage
  static withPageListRecord(payload) {
    const { msgType, msgContent, isSendSuccess } = payload;
    return new ChatMessage({
      type: msgType,
      content: msgContent,
      status: ChatMessage.isSendSuccess(isSendSuccess),
      ...pick(payload, ['createDate', 'id', 'sendUserId', 'isSendByCs'])
    });
  }
  // 设置错误信息 覆盖retryFunc 不覆盖id、createDate、errMsg
  static setStatus(setMessage) {
    return (options = {}) => {
      const { status, retryFunc } = options;
      const data = {
        status,
        retryFunc,
        ...pick(options, ['id', 'errMsg', 'createDate'])
      };
      setMessage(data);
    };
  }
  // 更新message
  static setMessage(commit) {
    return chatMessage => payload => {
      commit('SET_MESSAGE', { chatMessage, payload });
    };
  }
  // 创建客服的虚拟消息 虚拟消息没有ID
  static createInventedMsg({ type, content }) {
    return new ChatMessage({
      type,
      content,
      isSendByCs: '1',
      createDate: Date.now()
    });
  }
}
