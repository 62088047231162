<template>
  <div class="menu-wrapper">
    <template v-for="item in filterList(sortRoutes)">
      <el-menu-item
        :index="item.children[0].name"
        v-if="!item.children || hasOneShowingChildren(item.children)"
        :key="item.name"
      >
        <span slot="title">{{ item.meta.title }}</span>
      </el-menu-item>
      <el-submenu v-else :index="item.path" :key="item.name">
        <template slot="title">
          <span>{{ item.meta.title }}</span>
        </template>
        <template v-for="child in filterList(item.children)">
          <el-menu-item :index="child.name" :key="child.name">
            <span slot="title">{{ child.meta.title }}</span>
          </el-menu-item>
        </template>
      </el-submenu>
    </template>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';
export default {
  name: 'SidebarItem',
  props: {
    routes: {
      type: Array,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sortRoutes() {
      return sortBy(this.routes, 'sort');
    },
  },
  methods: {
    filterList(list) {
      return list.filter((item) => !(item.hidden === true));
    },
    hasOneShowingChildren(children) {
      const showingChildren = children.filter((item) => {
        return !item.hidden;
      });
      if (showingChildren.length === 1) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss">
.menu-wrapper {
  padding: 8px 0;
}
.popper-menu {
  .el-menu--popup {
    background-color: #001529;
    .el-submenu.is-active {
      .el-submenu__title {
        color: #666;
      }
    }
    .el-menu-item,
    .el-submenu__title {
      background-color: transparent !important;
      color: #666;
      &:hover {
        background-color: transparent;
        color: #fff;
      }
      &.is-active {
        color: #fff;
      }
    }
  }
}
.pad-title {
  padding-left: 20px;
}
</style>
