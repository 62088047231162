import composeMiddleware from '@/utils/composeMiddleware';
import useRetryUntilSuccess from '@/utils/useRetryUntilSuccess';
import { Message } from 'element-ui';
// 消息重试 并保存触发器
const retryMaker = setStatus => () =>
  new Promise(resolve => {
    setStatus({ status: 'fail', retryFunc: resolve });
  });
/**
 * 对发送消息函数进行柯里化
 * 1. 支持重试，执行失败通过触发器可触发重试
 * 2. 支持重连，从哪一步失败就从哪一步重新开始
 * @param {*} options
 */
export default function chatMessageSendMaker(options) {
  const { setStatus, commit, chatMessage } = options;
  const retry = retryMaker(setStatus);
  return {
    // 发送消息生成器
    sendMessageMaker: sendToCs =>
      composeMiddleware(
        useRetryUntilSuccess({
          retry
        }),
        async () => {
          /**
           * 重发分两种情况:
           *  1. 数据没有被创建
           *    置底刷新
           *  2. 微信调用失败
           *    原地刷新
           */
          if (chatMessage.status === 'fail' && !chatMessage.id) {
            commit('REFLOW_MESSAGE', chatMessage);
          }
          setStatus({ status: 'pending', errMsg: '' });

          try {
            const { data } = await sendToCs(chatMessage.body);
            const { createDate, msgId } = data;
            setStatus({ id: msgId, status: 'success', createDate });
          } catch (errorMsg) {
            Message.error(errorMsg || '发送失败');
            throw new Error(errorMsg);
          }
        }
      ),
    // 上传图片生成器
    uploadImageMaker: fetchUpload => sendMsg =>
      composeMiddleware(
        useRetryUntilSuccess({
          retry,
          success: sendMsg
        }),
        async () => {
          setStatus({ status: 'pending' });
          const { data } = await fetchUpload();
          commit('FILE_UPLOAD_DONE', {
            msgUid: chatMessage.uid,
            url: data
          });
        }
      )
  };
}
