
const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  userInfo: state => state.user.userInfo,
  name: state => state.user.userInfo && state.user.userInfo.shopName,
  carNumber: state => state.user.carNumber,
  unfinishedOrderNum: state => state.user.unfinishedOrderNum,
  approveStatus: state => state.user.approveStatus,
  isSupportWebp: state => state.app.isSupportWebp // 是否支持Webp
};   
export default getters;
