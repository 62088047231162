export default function getDomValue(elem, fileMap) {
  const res = {
    text: '',
    images: []
  };
  Array.from(elem.childNodes).forEach(child => {
    if (child.nodeName === '#text') {
      res.text += child.nodeValue;
    } else if (child.nodeName === 'BR') {
      res.text += '\n';
    } else if (child.nodeName === 'BUTTON') {
      res.text += this.getDomValue(child, fileMap).text;
    } else if (child.nodeName === 'IMG') {
      const emoji = child.getAttribute('data-emoji');
      if (emoji) {
        res.text += emoji;
      } else {
        res.images.push({
          url: child.src,
          file: fileMap && fileMap.get(child.src)
        });
      }
    } else if (child.nodeName === 'DIV') {
      const { text, images } = getDomValue(child, fileMap);
      res.text += '\n' + text;
      res.images = res.images.concat(images);
    }
  });
  res.text = res.text.replace(/</g, '&lt;');
  res.text = res.text.replace(/>/g, '&gt;');
  return res;
}

// 将链接转化为a标签
export const transLink = str => str && str.replace(/(https?:\/\/[^\s<>]+)/gi, '<a href="$1">$1</a>');
