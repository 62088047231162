import request, { withExtTenantIdRequest } from '@/utils/request';
// import axios from 'axios';

// export function login(username, password) {
//   return withExtTenantIdRequest({
//     url: '/ocean/api/auth/login',
//     method: 'post',
//     params: {
//       username,
//       password
//     }
//   });
// }

export function login (username, password) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantAuth/loginByPassword?mobile=${username}&password=${password}`,
    method: 'post'
  });
}

// 用户是否同步到商家端，soyoungzg/api/auth/isSyncMerchant  ,是-true,否-false
// export function authIsSyncMerchant () {
//   return withExtTenantIdRequest({
//     url: `/soyoungzg/api/auth/isSyncMerchant`,
//     method: 'post'
//   });
// }

// 登录验证 包含(用户是否同步到商家端、校验注销)
export function authLoginVerify () {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/auth/loginVerify`,
    method: 'post'
  });
}

export function loginWithCode (username, verificationCode) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantAuth/login?mobile=${username}&verificationCode=${verificationCode}`,
    method: 'post'
  });
}

export function logout () {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}

// 修改登录密码
export function forgetPassword (data) {
  return withExtTenantIdRequest({
    url: '/shopmember/api/memberAuth/forgetPassword',
    method: 'post',
    data
  });
}
// 修改支付密码
export function updatePayPassword (data, encrypted, timestamp) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/syzgAccountPay/updatePayPassword',
    method: 'post',
    data,
    headers: {
      'X-Tsign-Open-TIMESTAMP': timestamp, // 时间戳
      'X-Tsign-Open-SIGN': encrypted // 加密串
    }
  });
}

// 注册-校验用户是否注册
// export function izRegistered (mobile) {
//   return withExtTenantIdRequest({
//     url: `/shopmember/api/memberAuth/izRegistered?mobile=${mobile}`,
//     method: 'post'
//   });
// }

// 手机号登录验证、注册验证
export function memberAuthMobileVerify (data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/auth/mobileVerify`,
    method: 'post',
    data
  });
}


// 客户端-注册接口
export function register (data) {
  return withExtTenantIdRequest({
    url: '/shopmember/api/memberAuth/register',
    method: 'post',
    data
  });
}

// 来源
export function memberSourceCreate (data) {
  return withExtTenantIdRequest({
    url: '/shopmember/api/memberSource/create',
    method: 'post',
    data
  });
}

// 强制用户登出
export function authForceLogout () {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/auth/forceLogout',
    method: 'post',
  });
}
