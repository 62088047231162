// 公共 - 请求
export const FETCH_TIME_OUT = 'FETCH_TIME_OUT'; // 网络超时
export const DISCONNECT_NETWORK = 'DISCONNECT_NETWORK'; // 网络连接失败
export const SERVICE_INSIDE_ERROR = 'SERVICE_INSIDE_ERROR'; // 服务器内部错误
export const ACCESS_DENIED = 'ACCESS_DENIED'; // 未登录
export const FETCH_ERROR = 'FETCH_ERROR'; // 请求失败

// 核销 - 请求
export const BINDING_REPEAT = 'BINDING_REPEAT'; // 重复绑定
export const NON_BARCODE = 'NON_BARCODE'; // 商品不存在
export const FAILED_VALIDATE = 'FAILED_VALIDATE'; // 数据验证失败
export const COMMIT_SUCCESS = 'COMMIT_SUCCESS'; // 提交成功
export const FAILED_COMMIT = 'FAILED_COMMIT'; // 提交失败
// 核销 - 格式
export const BARCODE_NOT_EMPTY = 'BARCODE_NOT_EMPTY'; // 条形码不能为空
export const WRONG_BARCODE = 'WRONG_BARCODE'; // 条形码格式有误
export const WRONG_CODE = 'WRONG_CODE'; // 二维码格式有误
export const CODE_NOT_EMPTY = 'CODE_NOT_EMPTY'; // 二维码不能为空
export const CHECK_INPUT = 'CHECK_INPUT'; // 请检查格式
// 核销 - 操作
export const INPUT_NOT_FOCUS = 'INPUT_NOT_FOCUS'; // 请选中输入框
export const WAIT_TO_SEND = 'WAIT_TO_SEND'; // 提交中，请稍等
// 商品下架
export const CODE_NOT_EXIST = 'CODE_NOT_EXIST'; // 二维码不存在
export const CODE_STATUS_OFF = 'CODE_STATUS_OFF'; // 二维码已注销
export const NO_NEED_WRITE_OFF = 'NO_NEED_WRITE_OFF'; // 无需注销
export const FAILED_WRITE_OFF = 'FAILED_WRITE_OFF'; // 注销失败
export const SUCCESS_WRITE_OFF = 'SUCCESS_WRITE_OFF'; // 注销成功

const MESSAGE_MAPPING = {
  [BARCODE_NOT_EMPTY]: {
    text: '条形码不能为空', // 文案
    url: '' // 提示音
  },
  [WRONG_BARCODE]: {
    text: '条形码格式有误'
  },
  [WRONG_CODE]: {
    text: '二维码格式有误'
  },
  [CODE_NOT_EMPTY]: {
    text: '二维码不能为空'
  },
  [BINDING_REPEAT]: {
    text: '重复绑定'
  },
  [CHECK_INPUT]: {
    text: '请检查格式'
  },
  [FETCH_TIME_OUT]: {
    text: '网络超时'
  },
  [SERVICE_INSIDE_ERROR]: {
    text: '服务器内部错误'
  },
  [FETCH_ERROR]: {
    text: '请求失败'
  },
  [INPUT_NOT_FOCUS]: {
    text: '请选中输入框'
  },
  [WAIT_TO_SEND]: {
    text: '提交中，请稍等'
  },
  [COMMIT_SUCCESS]: {
    text: '提交成功'
  },
  [NON_BARCODE]: {
    text: '商品不存在'
  },
  [FAILED_VALIDATE]: {
    text: '数据验证失败'
  },
  [FAILED_COMMIT]: {
    text: '提交失败'
  },
  [DISCONNECT_NETWORK]: {
    text: '网络连接失败'
  },
  [ACCESS_DENIED]: {
    text: '请重新登录'
  },
  [CODE_NOT_EXIST]: {
    text: '二维码不存在'
  },
  [CODE_STATUS_OFF]: {
    text: '二维码已注销'
  },
  [NO_NEED_WRITE_OFF]: {
    text: '无需注销'
  },
  [FAILED_WRITE_OFF]: {
    text: '注销失败'
  },
  [SUCCESS_WRITE_OFF]: {
    text: '注销成功'
  }
};

Object.keys(MESSAGE_MAPPING).forEach(key => {
  const obj = MESSAGE_MAPPING[key];
  const fileName = `${key.toLowerCase()}.mp3`;
  obj.url = `https://static.syounggroup.com/zg/voice3/${fileName}?v=20180927`;
});

export class AudioMessage {
  constructor(audio) {
    this.audio = audio;
  }
  post(val) {
    const self = this;
    const message = MESSAGE_MAPPING[val];
    if (!message) { // 如果没有映射 直接输出
      return val;
    }
    const { text, url } = message;
    self.audio.play(url);
    return text;
  }
}

export function filterMessage(val) {
  if (!val) {
    return '';
  }
  const message = MESSAGE_MAPPING[val];
  if (!message) {
    return '';
  }
  return message.text;
}
