<template>
  <div class="text-content" :style="{ backgroundColor: isMine ? '#FDE8E8' : '#FFF' }">
    <slot>
      <div
        class="msg-text"
        v-for="(item, index) in values"
        :key="index"
        v-html="filterEmotionContent(item.trim())"
      ></div>
    </slot>
  </div>
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
import EmotionUtil from '@/helper/EmotionUtil';
import { transLink } from '@/utils/getDomValue';
const emotionUtil = new EmotionUtil({ baseSize: 20 });
export default {
  name: 'chat-msg-text',
  computed: {
    isMine() {
      return this.value.sendUserId === this.userInfo.id;
    },
    values() {
      return this.value.content
        .split('\n')
        .map((content) => content)
        .filter((val) => val);
    },
  },
  methods: {
    filterEmotionContent(content) {
      return transLink(emotionUtil.parseHTMLContent(content));
    },
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
    csInfo: Object,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.text-content {
  padding: 9px;
  max-width: 250px;
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  color: #222;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06);
}
.msg-text {
  vertical-align: bottom;
}
</style>
