// 防抖函数
// function debounce(func, delay, context, event) {
//   if (func.timer) clearTimeout(func.timer);
//   func.timer = setTimeout(function() {
//     func.call(context, event);
//   }, delay);
// }
// 节流函数
function throttle(func, wait = 500, immediate = true, context, event) {
	if (immediate) {
		if (!func.flag) {
			func.flag = true;
			// 如果是立即执行，则在wait毫秒内开始时执行
			typeof func === 'function' && func.call(context, event);
			func.timer = setTimeout(() => {
				func.flag = false;
			}, wait);
		}
	} else {
		if (!func.flag) {
			func.flag = true;
			// 如果是非立即执行，则在wait毫秒内的结束处执行
			func.timer = setTimeout(() => {
				func.flag = false;
				typeof func === 'function' && func.call(context, event);
			}, wait);
		}
	}
}
// 全局按钮事件拦截
export default {
  name: 'ButtonHoc',
  data() {
    return {};
  },
  props: {
    renderName: { // 渲染模板名称
      type: String,
      default: 'el-button'
    }
  },
  mounted() {},
  methods: {
    handleClickLink(event) {
      const that = this;
      //console.log('that', that.$attrs);
      // that.$listeners.click为绑定在新组件上的click函数
     throttle(that.$listeners.click, 1000, true, that, event);
    }
  },
  render(h) {
    const slots = Object.keys(this.$slots)
      .reduce((arr, key) => arr.concat(this.$slots[key]), [])
      .map(vnode => {
        vnode.context = this._self;
        return vnode;
      });
    return h(
      this.renderName,
      {
        on: {
          click: this.handleClickLink // 新组件绑定click事件
        },
        props: this.$props,
        // 透传 scopedSlots
        scopedSlots: this.$scopedSlots,
        attrs: this.$attrs
      },
      slots
    );
  }
};
