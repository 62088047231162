<template>
  <div class="app-breadcrumb-box">
    <span class="app-breadcrumb-box-begin">当前位置：</span>
    <el-breadcrumb class="app-breadcrumb" separator=">">
      <transition-group name="breadcrumb">
        <el-breadcrumb-item v-for="(item, index) in filterLevelList" :key="item.path">
          <span
            v-if="item.redirect === 'noredirect' || index == filterLevelList.length - 1"
            class="no-redirect"
            >{{ item.meta.title }}</span
          >
          <router-link v-else :to="item.redirect || item.path">{{
            item.meta.title
          }}</router-link>
        </el-breadcrumb-item>
      </transition-group>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  created() {
    this.getBreadcrumb();
  },
  data() {
    return {
      levelList: null,
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  computed: {
    filterLevelList() {
      const filterLevelList = (this.levelList || []).filter((item) => item.meta && item.meta.title);
      //console.log(filterLevelList);
      return filterLevelList;
    },
    ...mapGetters(['userInfo']),
    menuMap() {
      const { menus } = this.userInfo;
      const map = {};
      menus.forEach((menu) => {
        if (menu.href) {
          map[menu.href] = menu;
        }
      });
      return map;
    },
  },
  methods: {
    getBreadcrumb() {
      const matched = this.$route.matched.filter((item) => item.name);
      //const first = matched[0];
      // if (first && first.name !== '/user/shopList') {
      //   matched = [{ path: '/user/shopList', meta: { title: '主页' } }].concat(matched);
      // }
      this.levelList = matched;
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-breadcrumb-box {
  display: flex;
  align-items: center;
  .app-breadcrumb-box-begin {
    color: #999;
  }
}
.app-breadcrumb.el-breadcrumb {
  display: inline-block;
  line-height: 64px;
  margin-left: 10px;
  .no-redirect {
    color: #999;
    cursor: text;
  }
}
</style>
