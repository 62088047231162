import request from '@/utils/request';

export function eventReport(data) {
  return request({
    url: '/tracker-service/event/report',
    method: 'post',
    data
  });
}

export default {
  eventReport
};
