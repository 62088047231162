// 中间件：循环异步执行直至成功
export default function useRetryUntilSuccess(options) {
  const { retry, success } = options;
  return async (ctx, next) => {
    ctx.loop = true;
    ctx.times = 0;
    while (ctx.loop) {
      try {
        await next();
        ctx.loop = false;
      } catch (error) {
        console.error(error);
        ctx.times++;
        await retry(ctx);
      }
    }
    if (success) return success(ctx);
  };
}
