import Vue from 'vue';
import isEmpty from 'lodash/isEmpty';
export default function patchObj(target, obj) {
  if (isEmpty(obj)) {
    return;
  }
  Object.keys(obj).forEach(key => {
    Vue.set(target, key, obj[key]);
  });
}
