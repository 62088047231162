<template>
  <div
    class="text-content"
    :style="{ backgroundColor: isMine ? '#FDE8E8' : '#FFF' }"
    @click="toHome"
  >
    <slot>
      <div class="title">送你一张优惠券</div>
      <div class="coupon-box">
        <div class="coupon-wrap">
          <div class="circle circle-left"></div>
          <div class="circle circle-right"></div>
          <div class="coupon">
            <div class="price">
              <div>
                <span v-if="values.couponType === 'RATE_OFF'"
                  >{{ values.resultData || 0 }} <span class="symbol">折</span></span
                >
                <span v-else-if="values.couponType === 'MONEY_OFF'"
                  ><span class="symbol">￥</span>{{ values.resultData || 0 }}</span
                >
              </div>
              <div class="rule-box">
                <span class="rule-text" v-if="values.ruleData > 0"
                  >满{{ values.ruleData }}使用</span
                >
                <span class="rule-text" v-else>无门槛</span>
              </div>
            </div>
            <div class="card">
              <div class="title">{{ values.name }}</div>
              <div>
                <div class="intro">{{ values.dataTypeName }}</div>
                <div class="use">
                  有效期:{{ parseTime(values.validStartDate, '{y}.{m}.{d}') }}-{{
                    parseTime(values.validEndDate, '{y}.{m}.{d}')
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">去使用</div>
    </slot>
  </div>
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
import { parseTime } from '@/utils/date';
export default {
  name: 'chat-msg-text',
  computed: {
    isMine() {
      return this.value.sendUserId === this.userInfo.id;
    },
    values() {
      return JSON.parse(this.value.content);
    },
  },
  methods: {
    toHome() {
      this.$router.push('/');
    },
    parseTime(time, cFormat) {
      return parseTime(time, cFormat);
    },
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
    csInfo: Object,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.text-content {
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  color: #222;
  box-sizing: border-box;
  width: 280px;
  padding-top: 7px;
  background: #fff;

  & > .title {
    font-size: 13px;
    font-weight: 600;
    color: #222;
    line-height: 18px;
    letter-spacing: 1px;
    margin-left: 7px;
    padding-bottom: 7px;
  }
}

.coupon-box {
  width: 100%;
  height: 80px;
  background: #f8f8f8;
  border-radius: 4px;
  padding: 5px 0;
  margin-bottom: 13px;
}
.coupon-wrap {
  position: relative;
  overflow: hidden;
  width: 260px;
  margin-left: 10px;
  .circle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    position: absolute;
    background: #f8f8f8;
    z-index: 2;
    top: 50%;
    margin-top: -8.5px;
    border: 1px solid rgba(213, 213, 213, 0.7);
    &.circle-left {
      left: -6.5px;
    }
    &.circle-right {
      right: -6.5px;
    }
  }
  .coupon {
    position: relative;
    display: flex;
    align-items: center;
    height: 70px;
    box-shadow: 0 2px 5px 0 rgba(221, 221, 221, 0.5);
    background: #fff;
    border: 1px solid rgba(213, 213, 213, 0.7);
    .price {
      flex-shrink: 0;
      height: 100%;
      display: flex;
      position: relative;
      justify-content: center;
      align-content: center;
      flex-wrap: wrap;
      width: 73px;
      text-align: center;
      color: #fff;
      font-size: 17px;
      overflow: hidden;
      background: #d0021b;
      .symbol {
        font-size: 14px;
      }
      & > div {
        width: 100%;
        text-align: center;
        color: #fff;
      }
      .rule-box {
        line-height: 8px;
      }
      .rule-text {
        font-size: 10px;
      }
    }
    .card {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      text-align: left;
      padding: 7.5px;
      box-sizing: border-box;
      .title {
        width: 100%;
        color: #6b6b6b;
        overflow: hidden;
        height: 32px;
        font-size: 12px;
        font-weight: 400;
        color: rgba(34, 34, 34, 1);
        line-height: 16px;
        letter-spacing: 1px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .intro {
        width: 100%;
        font-size: 10px;
        color: #585858;
        line-height: 12px;
      }
      .use {
        width: 100%;
        display: block;
        color: #a1a1a1;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}
.btn {
  height: 30px;
  background: #AB0033;
  border-radius: 2px;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  font-weight: 400;
  color: #fff;
  margin: 13px 5px 13px 5px;
}
</style>
