// 菜单路由
export const menus = [
  {
    key: 'index',
    title: '首页',
    path: '/'
  },
  {
    key: 'brand',
    title: '品牌',
    path: '/brand-pavilion-self-support',
    children: [
      {
        key: 'pavilion-self-support',
        title: '国货品牌',
        path: '/brand-pavilion-self-support'
      },
      {
        key: 'pavilion-internationalization',
        title: '国际品牌',
        path: '/brand-pavilion-internationalization'
      }
    ]
  },
  {
    key: 'about',
    title: '关于我们',
    path: '/about'
  },
  {
    key: 'car',
    title: '立即采购',
    path: '/syoung/car/list'
  }
];
