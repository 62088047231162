<!-- 分销项目  图片压缩 图片格式处理 展示组件   图片处理为阿里云 oss 图片公共能力-->
<template>
  <img :src="ossSrc" v-bind="$attrs" @click="$emit('click')" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  name: 'oss-image',
  props: {
    src: String,
    format: {
      // 格式转换参数，转换存储在OSS内原图的格式
      type: String,
      default: 'webp', // jpg/png/webp/bmp/gif/tiff/heic/avif
    },
  },
  components: {},

  computed: {
    ...mapGetters(['isSupportWebp']),
    ossSrc() {
      if (!this.src) return '';
      const format = this.format;
      if (format === 'webp' && !this.isSupportWebp) {
        return this.src;
      }
      return this.ossformat(this.src, format);
    },
  },

  created() {},

  mounted() {},

  methods: {
    ossformat(src, type) {
      const q = `x-oss-process=image/format,${type}`;
      let s = `${src}?${q}`;
      if (src.indexOf('?') >= 0) {
        s = `${src}&${q}`;
      }
      return s;
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
