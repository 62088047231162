<template>
  <div class="msg-wrap" :style="{ flexDirection: isMine ? 'row-reverse' : 'row' }">
    <HeadImg :url="headImg"></HeadImg>
    <div class="chat-content">
      <!-- 不是自己的信息要带名称 -->
      <div class="chat-name" v-if="!isMine">{{ shopInfo.name }}</div>
      <component
        :is="component"
        :value="value"
        class="chat-msg-content"
        :userInfo="userInfo"
        :shopInfo="shopInfo"
        v-on="$listeners"
      ></component>
    </div>
    <div class="status-bar" v-if="value.isSendByCs === '0'">
      <i class="loading" v-loading="value.status === 'pending'"></i>
      <el-tooltip v-if="value.status === 'fail' && value.errMsg" :content="chatMessage.errMsg">
        <i class="el-icon-warning" @click="onRetryClick"></i>
      </el-tooltip>
      <i class="el-icon-warning" v-else-if="value.status === 'fail'" @click="onRetryClick"></i>
    </div>
  </div>
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
import HeadImg from '@/components/HeadImg';
// import { Loading } from 'vant';
import get from 'lodash/get';
import Text from './Text';
import Image from './Image';
import Goods from './Goods';
import Coupon from './Coupon';
import Gift from './Gift';
export default {
  name: 'ChatMessage',
  inject: ['chatProvider'],
  components: {
    HeadImg
    //Loading,
  },
  methods: {
    onRetryClick() {
      const { retryFunc } = this.value;
      const { autoScrollController } = this.chatProvider;
      if (autoScrollController && retryFunc) {
        autoScrollController(() => {
          retryFunc();
        });
      }
    }
  },
  computed: {
    headImg() {
      return this.isMine
        ? get(
            this.userInfo,
            'icon',
            'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/common/images/default-user-icon__1.png'
          )
        : get(
            this.shopInfo,
            'icon',
            'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/common/images/default-cs-icon__1.png'
          );
    },
    component() {
      return this.options[this.value.type];
    },
    options() {
      return {
        text: Text,
        image: Image,
        goods: Goods,
        coupon: Coupon,
        gift: Gift
      };
    },
    isMine() {
      return this.userInfo.id === this.value.sendUserId;
    }
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
    shopInfo: Object
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.msg-wrap {
  display: flex;
  padding: 0 5px;
  & + .msg-wrap {
    margin-top: 20px;
  }
}
.chat-msg-content {
  border-radius: 8px;
}
.chat-content {
  margin: 0 3px;
}
.chat-name {
  color: #666;
  font-size: 12px;
  margin-bottom: 6px;
}
.el-icon-warning {
  color: #d81e06;
  font-size: 15px;
  cursor: pointer;
}
.status-bar {
  display: flex;
  align-items: center;
  padding: 0 4px;
}
.loading {
  /deep/ .el-loading-spinner {
    top: 50%;
    margin-top: -9px;
    width: 100%;
    text-align: center;
    position: absolute;
  }
  /deep/ .circular {
    height: 15px;
    width: 15px;
  }
}
</style>
