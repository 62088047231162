// 表情
class Emotion {
  constructor(options = {}) {
    this.$options = options;
    const {
      // 表情符号
      code,
      // 雪碧图下标
      offset,
      // 微信标识
      wx_key,
      // 备注
      remark,
      // 雪碧图链接
      sprites,
      // 基准尺寸
      baseSize = 30,
      // 版本号
      version
    } = options;
    this.version = version;
    this.baseSize = baseSize;
    this.code = code;
    this.wx_key = wx_key;
    this.remark = remark;
    this.sprites = sprites;
    this.offset = offset;
  }
  // 获取背景图backgroundPosition属性
  get backgroundPosition() {
    const { baseSize, offset } = this;
    // return `0 -${(offset * baseSize) / 37.5}rem`;
    return `0 -${offset * baseSize}px`;
  }
  get backgroundPositionPX() {
    const { baseSize, offset } = this;
    return `0 -${offset * baseSize}px`;
  }
  // 获取表情包html
  get html() {
    const { baseSize, backgroundPositionPX, sprites } = this;
    return `<div contenteditable="false" style="vertical-align:bottom;display:inline-block;width:${baseSize}px;height:${baseSize}px;background-image:url('${sprites}');background-size:100%;font-size:${baseSize}px;background-position:${backgroundPositionPX}"></div>`;
  }
  // 格式转换
  static formatEmotions(emotions) {
    return JSON.stringify(
      emotions.map(({ title, name, desc, spritesIndex }) => ({
        code: title,
        offset: spritesIndex,
        wx_key: name,
        ...(desc && { remark: desc })
      }))
    );
  }
}

export default Emotion;
