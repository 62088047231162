<template>
  <div class="suspension-content">
    <div class="suspension" :class="isPackUp ? 'suspension--down' : 'suspension--up'">
      <template>
        <router-link :to="'/syoung/car/list'" class="suspension__box">
          <i class="iconfont icon-gouwuche"></i>
          <span class="suspension__box--triangle"></span>
          <span class="suspension__box--textBox">{{ carNumber > 9999 ? '9999+' : carNumber }}</span>
        </router-link>
        <router-link :to="'/syoung/order/inquiry/list'" class="suspension__box">
          <i class="iconfont icon-chakandindan"></i><span class="suspension__box--triangle"></span
          ><span class="suspension__box--textBox">{{
            unfinishedOrderNum > 999 ? '999+' : unfinishedOrderNum
          }}</span>
        </router-link>
        <div v-if="showChatBtn" class="chat-entrance suspension__box" @click="openChatShow">
          <i class="iconfont icon-kefu"></i>
          <span class="suspension__box--text">客服</span>
          <span v-show="customerDot" class="chat-entrance-dot"></span>
        </div>
        <el-popover placement="left" trigger="hover" width="240">
          <div class="customer-service" v-if="!userInfo">您还未登录，请先登录哦</div>
          <div v-else>
            <div class="customer-service2" v-if="auditFlag">
              <span @click="certification">您的账号在认证中，可以查看认证进度哦</span>
            </div>
            <div v-else>
              <div class="serviceIcon-box" v-if="CustomerService.name">
                <img :src="CustomerService.qrCode" />
                <p>
                  专属顾问<span>{{ CustomerService.name }}</span>
                </p>
                <p>请使用微信扫描二维码</p>
              </div>
              <div class="customer-service" v-else>您还暂无专属顾问哦</div>
            </div>
          </div>

          <div class="suspension__box" slot="reference">
            <i class="iconfont icon-zhuanshuguwen"></i>
            <span class="suspension__box--text">专属顾问</span>
          </div>
        </el-popover>
        <a href="https://syounggroup.feishu.cn/wiki/Ows9wJ79liHIchk2jRXcObhenXe" target="_blank" class="suspension__box">
          <i class="el-icon-collection" style="font-size: 16px"></i>
          <span class="suspension__box--text">帮助手册</span>
        </a>
        <router-link :to="'/'" class="suspension__box">
          <i class="iconfont icon-shouye"></i>
          <span class="suspension__box--text">首页</span>
        </router-link>
        <div class="suspension__box noBorder" @click="toTop">
          <i class="iconfont icon-zhiding"></i>
          <span class="suspension__box--text">TOP</span>
        </div>
        <template v-if="startService">
          <ChatFrame
            v-show="chatShow"
            @close="closeChatShow"
            :chatShow="chatShow"
            :startService="startService"
          />
        </template>
      </template>
    </div>
    <div class="suspension__operation" @click="operation" v-if="isPackUp">
      <span class="suspension__operation--text">收起</span>
      <i class="iconfont icon-jiantouxia"></i>
    </div>
    <div
      class="suspension__operation suspension__operation__no-border"
      @click="operation"
      v-if="!isPackUp"
    >
      <span class="suspension__operation--text">展开</span>
      <i class="iconfont icon-jiantoushang"></i>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ChatFrame from '@/components/ChatFrame';
import { getSwitchByType } from '@/api/chat';
import { getMyCustomerService } from '@/api/common/consultant';
export default {
  name: 'RightSuspension',
  components: {
    ChatFrame
  },
  data() {
    return {
      isPackUp: true, // 是否有收起按钮，默认是有的
      chatShow: false,
      startService: false,
      showChatBtn: false, // 显示客服按钮
      CustomerService: {
        name: '',
        qrCode: ''
      },
      auditFlag: false
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'carNumber', 'unfinishedOrderNum', 'approveStatus']),
    ...mapState('chat', ['customerDot'])
  },
  watch: {
    router() {
      this.closeChatShow();
    }
  },
  mounted() {
    this.isShowChatBtn();
    this.getMyCustomerService();
    if (this.userInfo) {
      this.initChatShow();
    }
  },
  methods: {
    ...mapActions(['GetAuthenticateInfo']),
    operation() {
      this.isPackUp = !this.isPackUp;
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    isShowChatBtn() {
      // 是否能显示客服按钮
      getSwitchByType().then((res) => {
        // 0 关 1开
        const { data } = res;
        this.showChatBtn = data === '1';
      });
    },
    initChatShow() {
      this.startService = true;
    },
    openChatShow() {
      if (!this.startService) this.startService = true;
      this.chatShow = true;
    },
    closeChatShow() {
      this.chatShow = false;
    },
    async getMyCustomerService() {
      if (this.userInfo) {
        this.GetAuthenticateInfo().then((res) => {
          this.auditFlag = this.approveStatus?.status !== 'PASS'; // NOT_SUBMIT
        });
        const { data } = await getMyCustomerService();
        this.CustomerService = data || {};
      }
    },
    certification() {
      this.$router.replace('/syoung/register/step');
    }
  }
};
</script>
<style lang="scss" scoped>
.suspension {
  position: fixed;
  right: 0;
  bottom: 50%;
  margin-bottom: -150px;
  z-index: 999;
  width: 60px;
  height: 360px;
  overflow: hidden;
  transition: height 0.5s;
  &--up {
    height: 0;
  }
  &--down {
    height: 360px;
  }
  &__operation {
    position: fixed;
    right: 0;
    bottom: 50%;
    margin-bottom: -186px;
    z-index: 999;
    width: 60px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(74, 74, 74, 0.88);
    border-top: 1px solid rgba(255, 255, 255, 0.24);
    color: #e5e5e5;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
    &__no-border {
      border: none;
    }
  }
  &__box {
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    background-color: rgba(74, 74, 74, 0.88);
    font-size: 12px;
    color: #ebebeb;
    padding: 12px 4px;
    cursor: pointer;
    &--triangle {
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 6px solid #AB0033;
    }
    &--textBox {
      font-size: 14px;
      color: #fff;
      min-width: 30px;
      height: 18px;
      background: #AB0033;
      border-radius: 2px;
      text-align: center;
    }
    &--text {
      margin-top: 6px;
    }
  }
}
.noBorder {
  border-bottom: none;
}
.serviceIcon-box {
  width: 210px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 210px;
  }
  p {
    text-align: center;
    margin: 0;
    line-height: 1.6;
    span {
      color: #333;
      font-weight: bold;
      margin-left: 10px;
    }
  }
}

.customer-service {
  width: 210px;
  height: 200px;
  text-align: center;
  line-height: 200px;
}

.customer-service2 {
  width: 210px;
  height: 200px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.chat-entrance {
  position: relative;
  &-dot {
    position: absolute;
    top: 8px;
    right: 14px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: red;
  }
}
</style>
