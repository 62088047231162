<template>
  <el-scrollbar wrapClass="scrollbar-wrapper">
    <el-menu :default-active="$route.path" :show-timeout="200" mode="vertical" unique-opened router>
      <div class="brand-mark-title">店铺管理</div>
      <sidebar-item :routes="routes"></sidebar-item>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from './SidebarItem';
export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['sidebar']),
    ...mapGetters('shop', ['menus']),
    routes() {
      return this.$router.options.routes;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-mark-title {
  font-size: 14px;
  color: #222;
  line-height: 28px;
  position: relative;
  padding-left: 10px;
  margin-left: 20px;
  &::after {
    content: '';
    left: 0;
    top: 7px;
    width: 4px;
    height: 16px;
    background: #AB0033;
    position: absolute;
  }
}
</style>
