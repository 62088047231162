import { withExtTenantIdRequest } from '@/utils/request';
// 首页banner
export const getBannerInfo = () => {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/officialHomepageRecommend/bannerInfo`,
    method: 'get'
  });
};
// 首页推荐位
export const recommendInfo = (type) => {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/officialHomepageRecommend/recommendInfo?type=${type}`,
    method: 'get'
  });
};

export const listLabelAndBrandInfo = () => {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/brandLabel/listLabelAndBrandInfo`,
    method: 'post'
  });
};

export const listAllForWebsite = () => {
  return withExtTenantIdRequest({
    url: `/commodity/api/commodityKind/listAllForWebsite`,
    method: 'post'
  });
};
export const commodityKind = (id) => {
  return withExtTenantIdRequest({
    url: `/commodity/api/commodityKind/get?id=${id}`
  });
};
