/**
 * url序列化
 * @param str
 * @returns {{query: {}, url: string}}
 */
export function parseUrl(str) {
  const rs = {
    query: {},
    url: ''
  };
  const idx = str.indexOf('?');
  if (idx === -1) {
    return rs;
  }
  // substr 左闭右开
  rs.url = str.substr(0, idx);
  const list = str.substr(idx + 1, str.length).split(/&/g);
  list.forEach((item) => {
    const keys = item.split('=');
    if (keys.length !== 2) {
      return;
    }
    const [key, value] = keys;
    if (rs.query[key] === undefined) {
      rs.query[key] = value;
      return;
    }
    if (Array.isArray(rs.query[key])) {
      rs.query[key].push(value);
      return;
    }
    rs.query[key] = [rs.query[key], value];
  });
  return rs;
}
