<template>
  <div class="head-image" :style="{ backgroundImage: `url(${url})` }"></div>
</template>

<script>
export default {
  name: 'HeadImg',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.head-image {
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
</style>
