<template>
  <div class="chat-view">
    <div class="message-content" @scroll="onMsgScroll" ref="message-content">
      <div class="loading" v-loading="pageListLoading" element-loading-text="加载中..."></div>
      <component
        v-for="{ key, component, props, listeners, slots } in groupTimeMessages"
        :key="key"
        :is="component"
        v-bind="props"
        v-on="listeners"
        >{{ slots && slots.default() }}</component
      >
    </div>
    <div class="footer">
      <div class="footer-top-box">
        <i class="iconfont icon-biaoqing" @click.stop="onEmotionClick" ref="page-view"></i>
        <i class="iconfont icon-tupian" @click="onUploadClick"></i>
        <input
          type="file"
          multiple="multiple"
          style="display: none"
          ref="upload-file"
          @blur="onRichTextBlur"
          accept="image/*"
          @change="onFileInputChange"
        />
      </div>
      <div class="textarea-box">
        <div class="textarea">
          <div
            @click="onRichTextClick"
            contenteditable="true"
            class="text-input"
            @input="onInput"
            ref="rich-text"
            @paste="onPaste"
            @blur="onRichTextBlur"
            @keyup.enter="onSubmit"
          ></div>
        </div>
        <div class="send-box">
          <div class="send-btn" @click="onSubmit">发送</div>
        </div>
      </div>
    </div>
    <Emotions @commit="onEmotionCommit" class="emotion" v-if="showEmotion"></Emotions>
    <viewer :images="images" ref="viewer" :options="viewerOptions" @inited="inited">
      <img style="display: none" v-for="item in images" :src="item.content" :key="item.uid" />
    </viewer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import ChatMessage from '@/components/ChatMessage';
import ChatTime from '@/components/ChatTime';
import Emotions from '@/components/Emotions';
import genAutoScrollController from '@/helper/genAutoScrollController';
import useChatScrollStateChange from '@/helper/useChatScrollStateChange';

import EmotionUtil from '@/helper/EmotionUtil';
import findLastIndex from 'lodash/findLastIndex';
const emotionUtil = new EmotionUtil();

// TODO: 暂不支持表情包和图片
// 保存当前光标位置，并返回重置函数
// eslint-disable-next-line no-unused-vars
function storeSelection() {
  const selection = document.getSelection();
  const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
  return () => {
    if (range) {
      const selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };
}

export default {
  name: 'chat',
  provide() {
    return {
      chatProvider: this,
    };
  },
  props: {
    chatShow: {
      type: Boolean,
      dedault: false,
    },
  },
  data() {
    return {
      viewerOptions: {
        initialViewIndex: 0,
      },
      images: [],
      showSendBtn: false,
      csInfo: {},
      memberInfo: {},
      // 是否显示表情
      showEmotion: false,
      emotions: Object.freeze(emotionUtil.emotions),
      lastEditRange: null,
    };
  },
  components: {
    ChatMessage,
    Emotions,
    ChatTime,
  },
  async created() {},
  async mounted() {
    this.startService();
  },
  computed: {
    ...mapState('chat', [
      'text',
      'messages',
      'currentUser',
      'pageListLoading',
      'hasNextPage',
      'autoScrollToBottom',
      'autoScrollController',
      'shopInfo',
    ]),
    ...mapGetters(['userInfo']),
    // 5分钟为一个时间跨度 显示时间
    groupTimeMessages() {
      const timeSpan = 1000 * 60 * 5;
      let lastTime = timeSpan * -1;
      const { currentUser, csInfo, onImageMounted, onImgPreview } = this;
      return this.messages.reduce((list, message) => {
        const createDate = message.createDate || 0;
        if (createDate && createDate - lastTime >= timeSpan) {
          lastTime = createDate;
          list.push({
            key: createDate,
            component: ChatTime,
            props: {
              date: createDate,
            },
          });
        }
        list.push({
          key: message.uid,
          component: ChatMessage,
          props: {
            value: message,
            userInfo: currentUser,
            csInfo,
            shopInfo: this.shopInfo,
          },
          listeners: {
            'image:mounted': () => onImageMounted(message),
            'image:load': () => message.onLoad && message.onLoad(),
            preview: onImgPreview,
          },
          slots: {
            default() {
              return message.content;
            },
          },
        });
        lastTime = createDate;
        return list;
      }, []);
    },
  },
  watch: {
    messages() {
      this.$nextTick(() => {
        this.scrollToBottom;
        // 找到最后一条客服回复的消息
        const idx = findLastIndex(this.messages, { isSendByCs: '1' });
        // 找到最后一条系统自动回复的消息
        const lastSysIdx = findLastIndex(this.messages, { isSendByCs: '2' });
        // 找到最后一条智能客服自动回复的消息
        const lastAutoIdx = findLastIndex(this.messages, { isSendByCs: '3' });
        if (
          this.messages.length > 1 &&
          this.messages.length - 1 - Math.max(idx, lastSysIdx, lastAutoIdx) < 1
        ) {
          this.CUSTOMER_DOT_SHOW(true);
        }
      });
    },
    async chatShow(bool) {
      if (bool) {
        await this.launch();
        this.onRichTextBlur();
      } else {
        // 5分钟自动关闭会话
        this.autoShutDown();
      }
    },
  },
  methods: {
    ...mapMutations('chat', ['INPUT', 'SET_STATE', 'CUSTOMER_DOT_SHOW']),
    ...mapActions('chat', [
      'launch',
      'sendToCs',
      'sendImgToCs',
      'fetchMsgPageList',
      'autoShutDown',
    ]),
    inited(viewer) {
      this.$viewer = viewer;
    },
    // 开启客服服务
    async startService() {
      const { getScrollElement } = this;
      const autoScrollController = genAutoScrollController({
        getScrollElement,
        useStateChange: useChatScrollStateChange,
      });
      this.SET_STATE({
        autoScrollController,
      });
      autoScrollController(async () => {
        await this.launch();
      });
    },
    // 图片消息挂载回调
    onImageMounted(message) {
      // 保证执行顺序 在初始化沉底后触发
      setTimeout(() => {
        const element = this.getScrollElement();
        const { scrollHeight, clientHeight, scrollTop } = element;
        // 如果图片消息挂载时
        if (scrollHeight - scrollTop - clientHeight < 10) {
          new Promise((rs) => {
            message.onLoad = rs;
          }).then(() => {
            this.scrollToBottom();
          });
        }
      }, 0);
    },
    getScrollElement() {
      return this.$refs['message-content'];
    },
    scrollToBottom() {
      const element = this.getScrollElement();
      if (!element || !this.autoScrollToBottom) {
        return;
      }
      element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    async onMsgScroll(e) {
      const { hasNextPage, pageListLoading } = this;
      const scrollTop = e.target.scrollTop;

      if (scrollTop < 20 && hasNextPage && !pageListLoading) {
        await this.fetchMsgPageList();
        // 留点距离方便下次触发触顶事件
        e.target.scrollTop = e.target.clientHeight;
      }
    },
    onPaste(event) {
      // 只支持复制文本
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      const selection = window.getSelection();
      if (!selection.rangeCount) return false;
      selection.deleteFromDocument();
      this.showSendBtn = !!paste;
      const text = document.createTextNode(paste);
      selection.getRangeAt(0).insertNode(text);
      selection.collapseToEnd();
      window.requestAnimationFrame(() => {
        const { scrollHeight, clientHeight } = this.$refs['rich-text'];
        this.$refs['rich-text'].scrollTop = scrollHeight - clientHeight;
      });
      event.preventDefault();
    },
    // 图片预览
    onImgPreview(chatMessage) {
      const imgMsgs = this.messages.filter((item) => item.type === 'image');
      const index = imgMsgs.findIndex((item) => item.uid === chatMessage.uid);
      this.viewerOptions.initialViewIndex = index;
      this.images = imgMsgs;
      setTimeout(() => {
        this.$viewer.show();
      }, 0);
    },
    onImgLoad() {
      this.scrollToBottom();
    },
    onUploadClick() {
      this.$refs['upload-file'].click();
    },
    onFileInputChange(e) {
      this.autoScrollController(() => {
        const files = e.target.files;
        // TODO: 这里要处理多个文件上传顺序序可能被打乱的情况
        Array.from(files).forEach((file) => this.sendImgToCs(file));
        // 清空file
        this.$refs['upload-file'].value = '';
        this.onRichTextBlur();
      });
    },
    onEmotionClick() {
      this.showEmotion = !this.showEmotion;
    },
    onRichTextBlur() {
      setTimeout(() => {
        this.getScrollElement().scrollTo(0, 1000000);
      }, 0);
    },
    onRichTextClick() {
      // 设置最后光标对象
      this.showEmotion = false;
      this.lastEditRange = getSelection().getRangeAt(0);
      setTimeout(() => {
        this.getScrollElement().scrollTo(0, 1000000);
      }, 10);
    },
    onEmotionCommit({ emotion }) {
      this.$refs['rich-text'].append(emotion.code);
      this.showSendBtn = !!this.getValue();
    },
    getDomValue(elem) {
      let res = '';
      Array.from(elem.childNodes).forEach((child) => {
        if (child.nodeName === '#text') {
          res += child.nodeValue;
        } else if (child.nodeName === 'BR') {
          res += '\n';
        } else if (child.nodeName === 'BUTTON') {
          res += this.getDomValue(child);
        } else if (
          child.nodeName === 'DIV' ||
          child.nodeName === 'P' ||
          child.nodeName === 'SPAN' ||
          child.nodeName === 'A'
        ) {
          res += '\n' + this.getDomValue(child);
        }
      });
      res = res.replace(/</g, '&lt;');
      res = res.replace(/>/g, '&gt;');
      return res;
    },
    getValue() {
      return this.getDomValue(this.$refs['rich-text']);
    },
    onInput() {
      this.showSendBtn = !!this.getValue();
      this.lastEditRange = getSelection().getRangeAt(0);
    },
    onSubmit() {
      if (this.getValue().trim() === '') {
        // 判空
        return;
      }
      this.autoScrollController(() => {
        this.sendToCs(this.getValue());
        this.$refs['rich-text'].textContent = '';
        this.showSendBtn = false;
        this.showEmotion = false;
      });
      this.scrollToBottom();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/chat/view.scss';
</style>
