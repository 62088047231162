import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import pickTure from '@/utils/pickTure';
import { getUserInfo } from '@/common/localStorage/user';
import get from 'lodash/get';
import Vue from 'vue';
import patchObj from '@/utils/patchObj';
import { uploadChatImage, sendToCs, fetchMsgPageList, fetchMsgPageListByAnon, sendToCsByAnon } from '@/api/chat';
import CustomService from '@/helper/CustomService';
import router from '@/router';
import { setItem as sessionStorageSetItem, getItem as sessionStorageGetItem } from '@/utils/sessionStorage';
import ChatMessage from '@/helper/ChatMessage';

import genAutoScrollController from '@/helper/genAutoScrollController';
import useScrollToBottomCheck from '@/helper/useScrollToBottomCheck';

const getChannelType = () => get(router.currentRoute, 'query.channelType');

// 获取初始化消息
const getInitMessages = () => [
  // ChatMessage.createInventedMsg({
  //   type: 'text',
  //   content:
  //     '小可爱，为了您后续更方便联系客服及查看订单发货物流、关注粉丝专享的活动福利信息，建议您在微信上关注‘水羊潮妆’公众号或者搜索小程序‘水羊潮妆官方商城’右上角添加到我的小程序中哟[玫瑰]'
  // }),
  // ChatMessage.createInventedMsg({
  //   type: 'image',
  //   content: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung/pc/soyoung-wx-barcode.jpg'
  // }),
  // ChatMessage.createInventedMsg({
  //   type: 'text',
  //   content: '水羊直供'
  // })
];

// action creator 在context中注入 sendMessage 工具函数
let sendMsgActionCreator;
const shopInfo = {
  icon: 'https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logo_500_500.png',
  id: 'soyoung-zg',
  name: '水羊直供',
};
// socket实例
let $socket;
// stomp实例
let $client;
// 自动重连timer
let $keepTimer;
// 启动进程
let $startUp;
// 自动关闭倒计时
let $autoShutDownTimer;
// 联系客服
export default {
  namespaced: true,
  state: {
    // 是否是游客模式
    isTourists: true,
    // 接待客服名称与图标
    shopInfo: shopInfo,
    // 当前用户信息
    currentUser: {},
    // 当前会话ID
    sessionId: '',
    // 消息数据
    messages: getInitMessages(),
    // 消息分页loading
    pageListLoading: true,
    // 分页标记更新时间
    markedUpdateDate: 0,
    // 是否有下一页
    hasNextPage: false,
    // 滚动条控制器
    autoScrollController: genAutoScrollController(),
    // 聊天窗口是否自动沉底，发消息、收消息、内容初始化、图片加载完成
    autoScrollToBottom: false,
    // 客服信息
    csMap: {},
    // 客服红点
    customerDot: false,
  },
  mutations: {
    ADD_MESSAGE (state, msg) {
      const { messages } = state;
      // 过滤掉重复ID 特殊情况下 如网络延迟情况下会出现
      if (msg.id && messages.some(item => item.id === msg.id)) {
        return;
      }
      messages.push(msg);
      // 取消红点

      state.customerDot = false;
      console.log(state.customerDot);
    },
    // 消息重发 成功后沉底显示
    REFLOW_MESSAGE (state, chatMessage) {
      const { messages } = state;
      const index = messages.findIndex(item => item.uid === chatMessage.uid);
      if (index === -1) {
        return;
      }
      const [message] = messages.splice(index, 1);
      messages.push(message);
    },
    SET_MESSAGE (_, { chatMessage, payload }) {
      patchObj(chatMessage, payload);
    },
    SAVE_USER (state, user = {}) {
      state.currentUser = user;
    },
    SET_IS_TOURISTS (state, bool = true) {
      //是否游客模式
      state.isTourists = bool;
    },
    SET_STATE (state, payload) {
      patchObj(state, payload);
    },
    // 加载分页信息
    LOAD_PAGE_LIST (state, data) {
      const { list, markedUpdateDate, hasNextPage } = data;
      state.markedUpdateDate = markedUpdateDate;
      state.messages.unshift(
        ...list.reverse().map(item => ChatMessage.withPageListRecord(item))
      );
      state.hasNextPage = hasNextPage;
    },
    // 更新客服数据
    UPDATE_CS_MAP (state, payload) {
      const { csMap } = state;
      const customService = csMap[payload.id];
      if (!csMap[payload.id]) {
        Vue.set(csMap, payload.id, payload);
      } else {
        patchObj(customService, payload);
      }
    },
    // 文件上传完成回调
    FILE_UPLOAD_DONE (state, { msgUid, url }) {
      const { messages } = state;
      const message = messages.find(item => item.uid === msgUid);
      if (!message) return;
      const blobUrl = message.url;
      message.content = url;
      URL.revokeObjectURL(blobUrl);
    },
    // 客服红点显示
    CUSTOMER_DOT_SHOW (state, data) {
      state.customerDot = data;
    },
  },
  actions: {
    launch ({ dispatch, commit }) {
      // 只启动一次
      if (!$startUp) {
        $startUp = dispatch('startUp').catch(err => {
          $startUp = null;
          throw err;
        })
          .finally(() => {
            commit('SET_STATE', { pageListLoading: false });
          });
      }
      return $startUp;
    },

    async updateUp ({ dispatch }) {
      // 更新用户状态
      if (!$startUp) return;
      await dispatch('startUp');
      await dispatch('reloadMsgPageList');
    },
    // 5分钟自动关闭
    autoShutDown ({ dispatch, commit }, timr = 5 * 60 * 1000) {
      $autoShutDownTimer = setTimeout(
        async () => {
          await dispatch('shutDown');
        },
        timr
      );
    },
    // 关闭5分钟自动关闭功能
    $autoShutDownTimerClose () {
      clearTimeout($autoShutDownTimer); // 清除定时器
    },
    // 关闭会话
    //! 要考虑启动一半然后中断会话的情况 要收集所有的副作用 按步骤中断副作用
    async shutDown ({ commit }) {
      // 如果正在启动就等待启动完毕后再关闭
      if ($startUp) await $startUp;
      // 断开stomp
      if ($client) $client.disconnect();
      // 断开stock
      if ($socket) $socket.close();
      // 关闭自动重连
      clearTimeout($keepTimer); // 清除定时器
    },
    async startUp ({ dispatch }) {
      // 建立连接：断开30秒自动重连
      (function keep (timeinterval, callback) {
        dispatch('connect', {
          onSuccess: () => {
            // 成功之后第一次断开马上重连
            timeinterval = 0;
            // 连接成功回调
            if (callback) callback();
          },
          onFail: () => {
            // 断开stomp
            $client.disconnect();
            // 断开socket
            $socket.close();
            // 30秒自动重试
            $keepTimer = setTimeout(
              () =>
                keep(30 * 1000, () => {
                  // 如果断开30秒 则重载消息列表
                  if (timeinterval) {
                    dispatch('reloadMsgPageList');
                  }
                }),
              timeinterval
            );
          }
        });
      })(30 * 1000);
      await dispatch('fetchMsgPageList');
    },
    connect ({ dispatch, rootState, commit, state }, { onSuccess, onFail }) {
      const userInfo = { ...getUserInfo() } || {};
      if (!userInfo.memberId) {
        // 没memberId 启动游客模式
        let sjid = `md${new Date().getTime()}`;
        if (sessionStorageGetItem('sightseerMemberId')) {
          sjid = sessionStorageGetItem('sightseerMemberId');
        } else {
          sessionStorageSetItem('sightseerMemberId', sjid);
        }
        commit('SET_IS_TOURISTS');
        userInfo.memberId = sjid;
      } else {
        commit('SET_IS_TOURISTS', false);
      }

      commit('SAVE_USER', { id: userInfo.memberId, ...userInfo });
      const { currentUser, isTourists } = state;
      const memberId = currentUser.id || null;
      if (!memberId) {
        return;
      }
      sendMsgActionCreator = ChatMessage.sendMsgActionCreator(isTourists ? sendToCsByAnon : sendToCs); // 发送消息对象
      const socket = new SockJS(process.env.VUE_APP_CHAT_URL);
      $socket = socket;
      $client = Stomp.over(socket);
      if (process.env.VUE_APP_ENV_CONFIG === 'prod') {
        // 正式环境关闭debug
        $client.debug = null;
      }

      const handle = action => res => {
        const data = JSON.parse(res.body);
        dispatch(action, data);
      };
      $client.connect(
        {
          token: memberId,
          role: 'member',

        },
        function onConnect () {
          if (onSuccess) onSuccess();
          $client.subscribe(
            '/user/topic/receiveMessage',
            handle('receiveMessage')
          );
          $client.subscribe(
            '/user/topic/receiveMember',
            handle('receiveMember')
          );
          $client.subscribe(
            '/user/topic/chatWaitQueueChangeNum',
            handle('chatWaitQueueChangeNum')
          );
        },
        // 断线自动重连
        function onError (error) {
          if (onFail) onFail(error);
        }
      );
    },
    // 结束会话
    receiveMessage ({ commit, state }, payload) {
      const { autoScrollController } = state;
      autoScrollController(() => {
        const chatMessage = ChatMessage.withReceiveMessage(payload);
        commit('ADD_MESSAGE', chatMessage);
      }, useScrollToBottomCheck);
    },
    // 网络波动 socket重连重载消息列表
    async reloadMsgPageList ({ commit, state }) {
      commit('SET_STATE', {
        pageListLoading: true
      });
      const { currentUser } = state;
      const memberId = currentUser.id;
      try {
        const gitList = state.isTourists ? fetchMsgPageListByAnon : fetchMsgPageList;
        const { data } = await gitList({ data: { memberId } });
        const { chatRecordVOList, csInfoMap } = data;
        // 重置messages
        commit('SET_STATE', {
          messages: getInitMessages()
        });
        // 重载message
        commit('LOAD_PAGE_LIST', chatRecordVOList);
        Object.keys(csInfoMap).forEach(key => {
          commit(
            'UPDATE_CS_MAP',
            CustomService.withListPage(key, csInfoMap[key])
          );
        });
      } finally {
        commit('SET_STATE', {
          pageListLoading: false
        });
      }
    },
    // 获取消息分页数据
    async fetchMsgPageList ({ state, commit }) {
      commit('SET_STATE', {
        pageListLoading: true
      });
      try {
        const { messages, markedUpdateDate, currentUser } = state;
        const lastId = get(
          messages.find(msg => msg.id),
          'id'
        );
        const memberId = currentUser.id;
        const gitList = state.isTourists ? fetchMsgPageListByAnon : fetchMsgPageList;
        const { data } = await gitList(
          pickTure({
            lastId,
            markedUpdateDate,
            data: {
              memberId
            }
          })
        );
        const { chatRecordVOList, csInfoMap } = data;
        commit('LOAD_PAGE_LIST', chatRecordVOList);
        Object.keys(csInfoMap).forEach(key => {
          commit(
            'UPDATE_CS_MAP',
            CustomService.withListPage(key, csInfoMap[key])
          );
        });
      } finally {
        commit('SET_STATE', {
          pageListLoading: false
        });
      }
    },
    chatWaitQueueChangeNum () { },
    // 发送文本给客服
    sendToCs: (context, payload) =>
      sendMsgActionCreator(
        context,
        payload
      )(({ commit, state, sendMessageCreator }, content) => {
        const chatMessage = new ChatMessage({
          type: 'text',
          content,
          sendUserId: state.currentUser.id,
          isSendByCs: '0',
          sendDate: Date.now(),
          channelType: getChannelType(),
          isTourists: state.isTourists
        });
        commit('ADD_MESSAGE', chatMessage);
        const { sendMsg } = sendMessageCreator(chatMessage);
        // 发消息
        sendMsg();
      }),
    // 发送图片给客服
    sendImgToCs: (...args) =>
      sendMsgActionCreator(...args)(
        ({ commit, state, sendMessageCreator }, file) => {
          const url = URL.createObjectURL(file);
          const chatMessage = new ChatMessage({
            content: url,
            type: 'image',
            isSendByCs: '0',
            sendUserId: state.currentUser.id,
            channelType: getChannelType(),
            isTourists: state.isTourists
          });
          commit('ADD_MESSAGE', chatMessage);
          const { sendMsg, uploadImg } = sendMessageCreator(chatMessage);
          // 先上传图片后发送消息
          const uploadThenSend = uploadImg(() => uploadChatImage(file))(
            sendMsg
          );
          uploadThenSend();
        }
      ),
    // 分页
    async onMsgScroll ({ state, dispatch }, e) {
      const { hasNextPage, pageListLoading } = state;
      const scrollTop = e.target.scrollTop;

      if (scrollTop < 20 && hasNextPage && !pageListLoading) {
        await dispatch('fetchMsgPageList');
        e.target.scrollTop = 20;
      }
    }
  }
};
