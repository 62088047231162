export function setItem(key, val) {
  localStorage.setItem(key, JSON.stringify(val));
}

export function getItem(key) {
  const obj = JSON.parse(localStorage.getItem(key));
  return obj;
}

export function removeItem(key) {
  localStorage.removeItem(key);
}
