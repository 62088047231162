import Vue from 'vue';
import horizontalScroll from './horizontal-scroll';

const modulesFiles = require.context('./modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
Object.keys(modules).forEach((k) => Vue.directive(k, modules[k]));

Vue.directive('horizontalScroll', horizontalScroll);
