<template>
  <div
    class="text-content"
    :style="{ backgroundColor: isMine ? '#FDE8E8' : '#FFF' }"
    @click="toCart"
  >
    <slot>
      <div class="title">送你一份礼包，已放入购物车</div>
      <div class="chat-msg-gift">
        <img
          :src="values.skuCommodityVO.thumbnailUrl"
          alt
          class="img"
          v-if="values.skuCommodityVO && values.skuCommodityVO.thumbnailUrl"
        />
        <div class="content">
          <div class="gift-name">{{ values.name }}</div>
          <div class="price">
            <span
              >￥{{ values.price }}元
              <span class="start-point">订单满{{ values.thresholdPrice }}元带走</span></span
            >
          </div>
          <div class="detail" v-if="values.validType === 'FIXED'">
            有效期:{{ parseTime(values.validStartDate, '{y}.{m}.{d}') }}-{{
              parseTime(values.validEndDate, '{y}.{m}.{d}')
            }}
          </div>
          <div class="detail" v-else>有效期:领取后{{ values.validDays }}天内有效</div>
        </div>
      </div>
      <div class="btn">去带走</div>
    </slot>
  </div>
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
import { parseTime } from '@/utils/date';
export default {
  name: 'chat-msg-text',
  computed: {
    isMine() {
      return this.value.sendUserId === this.userInfo.id;
    },
    values() {
      return JSON.parse(this.value.content);
    },
  },
  methods: {
    parseTime(time, cFormat) {
      return parseTime(time, cFormat);
    },
    toCart() {
      this.$router.push(`/cart`);
    },
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
    csInfo: Object,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.text-content {
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  color: #222;
  box-sizing: border-box;
  width: rem(520);
  padding-top: rem(14);
  background: #fff;

  & > .title {
    font-size: rem(26);
    font-weight: 600;
    color: #222;
    line-height: rem(37);
    letter-spacing: rem(1);
    margin-left: rem(14);
    padding-bottom: rem(14);
  }
}
.chat-msg-gift {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  background: #f8f8f8;
  padding: rem(10);
  .img {
    flex: none;
    width: rem(146);
    height: rem(146);
    border-radius: rem(8);
    border: rem(1) solid rgba(233, 233, 233, 1);
  }
  .content {
    width: 100%;
    padding: 0 rem(10);
    box-sizing: border-box;
    height: rem(146);
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    text-align: left;
    .gift-name {
      width: rem(334);
      height: rem(68);
      font-size: rem(22);
      font-weight: 400;
      color: rgba(34, 34, 34, 1);
      line-height: rem(34);
      letter-spacing: rem(1);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price {
      width: 100%;
      font-size: rem(24);
      color: #ce0e2d;
      line-height: rem(33);
    }
    .start-point {
      background: rgba(215, 9, 47, 1);
      border-radius: rem(4);
      font-size: rem(20);
      line-height: rem(28);
      color: #fff;
      padding: rem(3) rem(10);
      margin-left: rem(4);
    }
    .detail {
      width: 100%;
      font-size: rem(20);
      color: #999;
      line-height: rem(28);
    }
  }
}
.btn {
  width: rem(500);
  height: rem(60);
  background: #AB0033;
  border-radius: rem(4);
  text-align: center;
  line-height: rem(60);
  font-size: rem(26);
  font-weight: 400;
  color: #fff;
  margin: rem(26) rem(10) rem(26) rem(10);
}
</style>
