<template>
  <div class="declare-wrap" v-if="isShow">
    <div class="declare">
      <div class="declare__item">
        <i class="iconfont icon-pinpaizhigong_zhigongguangwang"></i>
        <div class="declare__text">
          <p class="declare__text__title">品牌直供</p>
          <p>无中间商，与品牌直接做生意</p>
        </div>
      </div>
      <div class="declare__item">
        <i class="iconfont icon-zhengpinbaozheng_zhigongguangwang"></i>
        <div class="declare__text">
          <p class="declare__text__title">正品保证</p>
          <p>品牌货源，直发100%正品</p>
        </div>
      </div>
      <div class="declare__item">
        <i class="iconfont icon-1V1_zhigongguangwang"></i>
        <div class="declare__text">
          <p class="declare__text__title">1V1服务经理</p>
          <p>服务直接，简单高效对接</p>
        </div>
      </div>
      <div class="declare__item">
        <i class="iconfont icon-shouhouwuyou_zhigongguangwang"></i>
        <div class="declare__text">
          <p class="declare__text__title">售后无优</p>
          <p>可开发票，贴心售后</p>
        </div>
      </div>
      <div class="declare__item">
        <i class="iconfont icon-jisuwuliu_zhigongguangwang"></i>
        <div class="declare__text">
          <p class="declare__text__title">极速物流</p>
          <p>分仓管控，高时效配送</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Declare',
  data() {
    return {
      hiddenBlackList: ['/rights', '/index'],
    };
  },
  computed: {
    isShow(){
      return !this.hiddenBlackList.includes(this.$route.name);
    }
  },
};
</script>

<style lang="scss" scoped>
$color-primary: #AB0033;;
.declare-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}
.declare {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1380px;
  /* position: absolute;
  padding: 0 272px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  &__item {
    display: flex;
    align-items: center;
    color: #6d6d6d;
  }
  &__text {
    margin-left: 10px;
    font-size: 14px;
    &__title {
      font-weight: 600;
      font-size: 18px;
      color: #393939;
    }
  }
}
.iconfont {
  height: 40px;
  line-height: 40px;
  color: $color-primary;
  font-size: 40px;
  margin-left: 3px;
  vertical-align: center;
}
</style>
