import { withExtTenantIdRequest } from '@/utils/request';

// 通过类型查找字典对象集合
export function getDicListByType(type) {
  return withExtTenantIdRequest({
    url: `/common/api/dict/listByType?type=${type}`,
    method: 'get'
  });
}

// 获取我的专属客服对象,必须是登录状态
export function getMine() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/customerService/getMine`,
    method: 'get'
  });
}

// 获取我的认证信息，(如审核不通过去补充资料)必须是登录态。没有认证则返回空
export function getMyInfo() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributor/getMyInfo`,
    method: 'get'
  });
}

// 获取我的认证信息，(如审核不通过去补充资料)必须是登录态。没有认证则返回空
export function reSubmit(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributor/reSubmit`,
    method: 'post',
    data
  });
}

// 创建分销商,分销商填写完资料后发起认证（客户端需要登录态，必须带token）
export function submitCreate(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributor/submit`,
    method: 'post',
    data
  });
}

// 获取认证状态，必须是登录态。没有认证则返回空
export function getAuthenticateInfo() {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributor/getAuthenticateInfo`,
    method: 'get'
  });
}
// 补充分销商拓展顾问信息,必须是登录状态
export function customerServiceSupplementDevCs(devCsId) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/customerService/supplementDevCs?devCsId=${devCsId}`,
    method: 'get'
  });
}

// 补充分销商信息,补充完资料后发起资质待审核
export function distributorSupplementInfo(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/distributor/supplementInfo`,
    method: 'post',
    data
  });
}
