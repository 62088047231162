<template>
  <div class="chat-dialog">
    <div class="chat-head">
      <img class="chat-head_icon" :src="shopInfo.icon" />
      <div class="chat-head_left">
        <div>
          <div class="chat-head_name">{{ shopInfo.name }}</div>
          <div class="chat-head_tip">在线客服</div>
        </div>
      </div>
      <div class="chat-line-btn" @click="closeChatShow"></div>
    </div>
    <div class="chat-box">
      <Chat v-bind="$attrs" />
    </div>
  </div>
</template>

<script>
import Chat from '@/components/Chat';
import { mapState } from 'vuex';
export default {
  name: '',
  components: { Chat },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {
    ...mapState('chat', ['shopInfo']),
  },
  methods: {
    closeChatShow() {
      this.$emit('close');
    },
  },
};
</script>

<style lang='scss'  scoped>
.chat-dialog {
  position: fixed;
  z-index: 100;
  bottom: 20px;
  right: 75px;
  width: 360px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.23);
}
.chat-box {
  height: 480px;
}
.chat-head {
  height: 50px;
  padding: 0 8px;
  background: #AB0033;
  color: #ebebeb;
  display: flex;
  align-items: center;
  position: relative;
  &_icon {
    width: 34px;
    height: 34px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 10px;
  }
  &_name {
    font-weight: 600;
    font-size: 12px;
  }
  &_tip {
    margin-top: 2px;
    font-size: 10px;
  }
}
.chat-line-btn {
  position: absolute;
  right: 13px;
  top: 12px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 16px;
    height: 2px;
    background: #fff;
    left: 4px;
    top: 12px;
  }
  &:hover {
    &::after {
      background: #ccc;
    }
  }
}
</style>