<template>
  <div
    class="text-content"
    :style="{ backgroundColor: isMine ? '#FDE8E8' : '#FFF' }"
    @click="toGoodsDetail"
  >
    <slot>
      <div class="chat-msg-goods msg-text">
        <img :src="values.thumbnailUrl" alt class="img" />
        <div class="content">
          <div class="goods-name">{{ values.name }}</div>
          <div class="price">￥{{ loginFlag ? values.price : '??' }}</div>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
export default {
  name: 'chat-msg-text',
  computed: {
    isMine() {
      return this.value.sendUserId === this.userInfo.id;
    },
    values() {
      return JSON.parse(this.value.content);
    },
    loginFlag() {
      return this.userInfo.status === 'PASS';
    },
  },
  methods: {
    toGoodsDetail() {
      this.$router.push(`/syoung/commodity/goods-detail/${this.values.id}`);
    },
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
    csInfo: Object,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.text-content {
  display: inline-block;
  white-space: normal;
  word-break: break-all;
  color: #222;
  box-sizing: border-box;
}
.msg-text {
  vertical-align: bottom;
}

.chat-msg-goods {
  width: 280px;
  height: 83px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  .img {
    width: 73px;
    height: 73px;
    border-radius: 4px;
    margin: 0 5px;
    border: 1px solid rgba(233, 233, 233, 1);
  }
  .content {
    height: 73px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    text-align: left;
    .goods-name {
      width: 167px;
      height: 34px;
      font-size: 12px;
      font-weight: 400;
      color: rgba(34, 34, 34, 1);
      line-height: 17px;
      letter-spacing: 1px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .price {
      width: 100%;
      font-size: 13px;
      color: #ce0e2d;
      line-height: 16.5px;
    }
  }
}
</style>
