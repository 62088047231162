<template>
  <div v-show="isShow">
    <div :class="$route.name === '/index' ? 'footer home' : 'footer'">
    <div class="footer__main">
      <div class="footer__left">
        <img
          alt
          class="footer__logo"
          @click="goHome"
          src="https://oss.syounggroup.com/static/file/soyoung-zg/aliyun/soyoung-zg/website/logoLevel/logoName_180_50.png"
        />
        <div class="footer__slogan">成就每一个客户</div>
      </div>
      <div class="footer__right">
        <div class="footer__line">联系方式：</div>
        <div class="footer__line">客户服务中心 400 825 9920</div>
        <div class="footer__imgbox">
          <div class="footer__wechat">
            <img
              alt
              class="footer__img"
              src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/url-code-footer.png"
            />
            <p class="footer__imgtext">微信小程序</p>
          </div>
          <div>
            <img
              alt
              class="footer__img"
              src="https://oss.syounggroup.com/static/file/soyoung-zg/official-website/website-kefu-v2.png"
            />
            <p class="footer__imgtext">企业微信</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__copyright">
      <a href="https://beian.miit.gov.cn/#/Integrated/index">©2023长沙水羊网络科技有限公司</a>
      <img src="https://oss.syounggroup.com/static/file/soyoung-zg/wanganbeian-icon.png" class="footer__copyright-icon" />
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=43019002001485" rel="noreferrer" target="_blank">湘公网安备43019002001485</a>
      <a class="footer__copyright-num" href="https://beian.miit.gov.cn/#/Integrated/index">湘ICP备2023020024号</a>
    </div>
  </div>
  </div>
</template>

<script>
import { menus } from '@/common/website/websiteMenus.js';
import eventReporter from '@/utils/event-reporter';
export default {
  name: 'Footer',
  data() {
    return {
      listFooter: menus,
      hiddenBlackList: ['/rights'],
    };
  },
  computed: {
    isShow(){
      return !this.hiddenBlackList.includes(this.$route.name);
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    // 菜单跳转添加埋点
    jumpTo(path) {
      if (path === '/brand-pavilion-self-support') {
        eventReporter.trackClick(this.$route, {
          name: 'zg_nav_click',
          aliasName: '点击菜单',
          event_source: 'brand_pavilion_self_support',
        });
      }
      if (path === '/brand-pavilion-internationalization') {
        eventReporter.trackClick(this.$route, {
          name: 'zg_nav_click',
          aliasName: '点击菜单',
          event_source: 'brand_pavilion_internationalization',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: #efefef;
  color: #333;

  &.home {
    background: #000000;
    color: #fff;
  }

  &__main {
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    padding-top: 40px;
    width: 78vw;
    min-width: 1000px;
    font-size: 12px;
  }

  &__slogan {
    line-height: 18px;
  }

  &__left {
    width: 197px;
    text-align: center;
    font-size: 14px;
  }

  &__logo {
    width: 100%;
    display: block;
    margin-bottom: 25px;
    cursor: pointer;
  }

  &__right {
    text-align: left;
    font-size: 10px;
    line-height: 14px;
  }

  &__line {
    margin-bottom: 8px;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  &__imgbox {
    display: flex;
  }

  &__img {
    width: 110px;
    display: block;
    margin: 7px 0 17px 0;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #dddddd;
  }

  &__imgtext {
    width: 100%;
    text-align: center;
  }

  &__wechat {
    margin-right: 14px;
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    height: 38px;
    line-height: 38px;
    &-icon{
      width: 16px;
      margin: 0 5px;
    }
  }

  &__copyright-num {
    margin-left: 5px;
  }
}
</style>
