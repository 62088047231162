// 事件代理 收集data-proxy
export default function generatorEventProxy(refKey, callback, proxyFunc) {
  return function(e) {
    let node = e.target;
    let proxy;
    let proxyKey;
    const rootNode = this.$refs[refKey];
    if (!rootNode || !rootNode.contains(node)) {
      return;
    }
    while (node && node !== rootNode) {
      // 如果有proxyFunc 且返回为真 则直接触发callback
      if (proxyFunc && proxyFunc(node) && callback) {
        callback.call(this, e);
        return;
      }
      if (node.dataset && node.dataset.proxy) proxy = node.dataset.proxy;
      if (node.dataset && node.dataset.proxyKey) {
        proxyKey = node.dataset.proxyKey;
      }
      node = node.parentNode;
    }
    if (this[`${proxy}Proxy`]) {
      this[`${proxy}Proxy`](e);
      return;
    }
    if (proxyKey && callback) callback.call(this, proxyKey, e);
  };
}
