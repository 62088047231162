import BrowserLogger from 'alife-logger';

const __bl = BrowserLogger.singleton({
  pid: '156t@8baf7ef76c043de',
  enableSPA: true,
  disableHook: true,
  imgUrl: 'https://arms-retcode.aliyuncs.com/r.png?', // 设定日志上传地址,新加坡部署可选`https://arms-retcode-sg.aliyuncs.com/r.png?`
  environment: process.env.VUE_APP_MONITOR_ENV
  // 其他config配置
});

const monitor = {
  __bl,
  api(api, success, time, code, msg) {
    __bl.api(api, success, time, code, msg);
  },
  error(err, pos) {
    __bl.error(err, pos);
  },
  /**
   * 设置应用版本号，在ARMS初始化时调用，如： main.js里
   * @param {String} version 应用版本号
   */
  setAppVersion(version) {
    __bl.setConfig({ release: version });
  },
  setOpenId(openId) {
    __bl.setConfig({ uid: openId });
    __bl.setCommonInfo({ uid: openId });
  },
  setUserId(userId) {
    __bl.setConfig({ uid: userId });
    __bl.setCommonInfo({ uid: userId });
  },
  setUsername(value) {
    __bl.setConfig({
      setUsername: function() {
        return value;
      }
    });
  },
  setEnvironment(env = 'daily') {
    __bl.setCommonInfo({ environment: env });
  },
  setCommonInfo(data) {
    __bl.setCommonInfo(data);
  },
  setConfig(data) {
    __bl.setConfig(data);
  },
  sum(key, value) {
    __bl.sum(key, value);
  }
};

export default monitor;
