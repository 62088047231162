import store from '@/store';
export default async function useScrollChangeState(ctx, next) {
  // ignore: 直接跳过状态变更
  if (ctx.ignoreScrollState) {
    await next();
    return;
  }
  store.commit('chat/SET_STATE', { autoScrollToBottom: true });
  await next();
  // 异步执行，避免同步变更时，在watch前面触发
  setTimeout(() => {
    store.commit('chat/SET_STATE', { autoScrollToBottom: false });
  }, 0);
}
