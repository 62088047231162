const design = {
  state: {
    showTool: false,
    AddCompPos: null
  },
  getters: {
    showTool: state => state.showTool,
    AddCompPos: state => state.AddCompPos
  },
  mutations: {
    SET_SHOWTOOL: (state, val) => {
      state.showTool = val;
    },
    SET_ADD_COMP_POS: (state, val) => {
      state.AddCompPos = val;
    }
  },

  actions: {}
};

export default design;
