<template>
  <img
    class="chat-msg-img img"
    :src="value.content"
    alt="图片加载失败"
    @load="$emit('image:load', $event)"
    @click="$emit('preview', value)"
  />
</template>

<script>
import ChatMessage from '@/helper/ChatMessage';
export default {
  name: 'chat-msg-Image',
  mounted() {
    this.$emit('image:mounted');
  },
  computed: {
    isMine() {
      return this.value.sendUserId === this.userInfo.id;
    },
  },
  props: {
    value: ChatMessage,
    userInfo: Object,
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/base/index.scss';
.img {
  max-width: 250px;
  max-height: 206px;
  min-width: 90.5px;
  min-height: 40px;
  background-color: #fff;
  background-size: cover;
  background-position: 50% 50%;
  cursor: zoom-in;
}
</style>
