import { withExtTenantIdRequest } from '@/utils/request';

// 根据ID获取微页面对象
export function featureGetDetail(id) {
  return withExtTenantIdRequest({
    url: `/marketing/api/feature/getDetail?id=${id}`,
    method: 'get'
  });
}

// 获取微页面分页列表 - 协议
export function featureListByCategory(data) {
  return withExtTenantIdRequest({
    url: `/marketing/api/feature/listHistory`,
    method: 'post',
    data,
  });
}


/**
 * APP-获取小程序短链
 * @param data
 */
export function shortUrlGenerateWxUrlScheme(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shortUrl/generateWxUrlScheme',
    method: 'post',
    data
  });
}

export function disclaimerGetByTypeForApp(type) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/featureConfig/getByTypeForApp?type=${type}`,
    method: 'get'
  });
}
