import router from '@/router';
import store from '@/store';
// import { Message } from 'element-ui';
// import NProgress from 'nprogress'; // Progress 进度条
// import 'nprogress/nprogress.css'; // Progress 进度条样式
import { getUserInfo } from '@/common/localStorage/user';
import { setItem, getItem } from '@/utils/cookie';
const whiteList = [
  '/',
  '/brand-pavilion-self-support',
  '/brand-pavilion-internationalization',
  '/about',
  '/syoung/404',
  '/syoung/401',
  '/syoung/login',
  '/syoung/logout',
  '/syoung/register/forgetPsd',
  '/syoung/register',
  '/agreement',
  '/static',
  '/agreement/contact',
  '/syoung/register/step',
]; // 不重定向白名单

// 冻结可跳转页面
const disableList = [
  ...whiteList,
  '/syoung/shop/detail',
  '/syoung/shop/cash-deposit-list',
  '/syoung/shop/account-balance-list',
  '/syoung/shop/rebate-list',
  '/syoung/order/inquiry/list',
  '/syoung/order/refund/list',
  '/syoung/order/inquiry/detail/:id',
  '/syoung/order/refund/detail/:id',
  '/syoung/order/inquiry/package/:id',
  '/syoung/order/refund/delivery_status/:companyCode/:deliveryNo',
  '/syoung/invoice/invoice-record',
  '/syoung/invoice/invoice-order',
  '/syoung/invoice/invoice-header',
  '/syoung/shop/disable',
  '/agreement',
];

router.beforeEach((to, from, next) => {
  const approveStatus = store.getters.approveStatus || getItem('aS'); // 是否认证
  // NProgress.start();
  const userInfo = store.getters.userInfo;
  const userStore = getUserInfo();
  if (!userInfo && userStore) {
    store.commit('SET_USER', userStore);
  }

  const user = userInfo || userStore;
  if (to.path === '/syoung/login' && user) {
    // 已经登录 不显示登录页
    next('/');
    return;
  }
  if (!user && whiteList.indexOf(to.path) > -1) {
    // 没登录 但是在白名单
    next();
    return;
  }
  if (!user) {
    const { fullPath } = to;
    next(`/syoung/login?redirect=${fullPath}`);
    return;
  }

  if (
    whiteList.indexOf(to.path) === -1 &&
    (approveStatus === null ||
      (approveStatus instanceof Object && (approveStatus.status === 'WAIT_AUDIT' || approveStatus.status === 'REJECT'))) &&
    to.path !== '/syoung/register/step'
  ) {
    // 未认证
    setItem('toUrl', to.fullPath);
    next('/syoung/register/step');
    return; 
  }

  if (
    (disableList.indexOf(to.path) === -1 && disableList.indexOf(to.name) === -1) && approveStatus.status === 'DISABLE'
  ) {
   
    // 冻结账号跳转
    next('/syoung/shop/disable');
    return;
  }
  next();
});

router.afterEach(to => {
  const { pageTitle, title } = to.meta || {};
  document.title = pageTitle || title || process.env.VUE_APP_SYS_NAME;
  // NProgress.done(); // 结束Progress
});
