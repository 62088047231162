import { DEVELOP_CUSTOMER_SERVICE_PARAMS, USER_INFO } from '../constants';
import { setItem, getItem, removeItem } from '@/utils/localStorage';

export function setUserInfo(user) {
  setItem(USER_INFO, user);
}

export function getUserInfo() {
  return getItem(USER_INFO);
}

export function removeUserInfo() {
  removeItem(USER_INFO);
}


export function setDevelopCustomerServiceParams(params) {
  setItem(DEVELOP_CUSTOMER_SERVICE_PARAMS, params);
}

export function getDevelopCustomerServiceParams() {
  return getItem(DEVELOP_CUSTOMER_SERVICE_PARAMS);
}
