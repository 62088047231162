import { withExtTenantIdRequest } from '@/utils/request';

export function getTodayStatistics() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/orderStatistics/getTodayStatistics',
    method: 'get',
  });
}
export function getYesterdayStatistics() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/orderStatistics/getYesterdayStatistics',
    method: 'get',
  });
}
export function getShopStatisticToday() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/getShopStatisticToday',
    method: 'get',
  });
}
export function getShopStatisticYestoday() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/getShopStatisticYestoday',
    method: 'get',
  });
}
export function listHotCommodity(dayType = 'LATEST_7_DAYS') {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/listHotCommodity',
    method: 'get',
    params: { dayType },
  });
}

export function listMoreCommodity(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/listMoreHotCommodity',
    method: 'post',
    data,
  });
}

export function listPayAmount(postData) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/listPayAmount',
    method: 'post',
    data: postData,
  });
}
export function listNewMemberCount(postData) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/shopStatistic/listNewMemberCount',
    method: 'post',
    data: postData,
  });
}

// 获取活动通知记录分页列表 、历史记录等
export function messagelistPage(data) {
  return withExtTenantIdRequest({
    url: '/notify-service/api/alarmMessage/listPage',
    method: 'post',
    data,
  });
}
// 全部已读
export function readAll(data) {
  return withExtTenantIdRequest({
    url: `/notify-service/api/alarmMessage/readAll`,
    method: 'post',
    data,
  });
}
// 全部清除
export function clearByIds(data) {
  return withExtTenantIdRequest({
    url: `/notify-service/api/alarmMessage/clearByIds`,
    method: 'post',
    data,
  });
}
// 已读
export function readById(id) {
  return withExtTenantIdRequest({
    url: `/notify-service/api/alarmMessage/read?id=${id}`,
    method: 'post',
  });
}

// 单独清除
export function clear(id) {
  return withExtTenantIdRequest({
    url: `/notify-service/api/alarmMessage/clear?id=${id}`,
    method: 'post',
  });
}

// 营销活动字典
export function fetchNotifyOptions() {
  return withExtTenantIdRequest({
    url: '/common/api/dict/listByType?type=notify_alarm_message_bizType',
    method: 'get',
  });
}

// 库存过少的规格：上架商品中，库存少于等于10的规格
export function getLowStock() {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/getLowStock',
    method: 'get',
  });
}

// 已售罄的规格：上架商品中，库存为0的规格
export function getNoneStock() {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/getNoneStock',
    method: 'get',
  });
}

// 库存过少 、已售罄SKU列表(最多支持100条数据)
export function getListLowOrNoneStock(data) {
  return withExtTenantIdRequest({
    url: '/commodity-service/api/commodity/listLowOrNoneStockCommodity',
    method: 'post',
    data,
  });
}

// 保存短信活动告警管理
export function smsAlarmConfigSave(data) {
  return withExtTenantIdRequest({
    url: '/sms-service/api/smsAlarmConfig/save',
    method: 'post',
    data,
  });
}

// 获取短信活动告警管理列表
export function smslistAll(data) {
  return withExtTenantIdRequest({
    url: '/sms-service/api/smsAlarmConfig/listAll',
    method: 'post',
    data,
  });
}

// 获取短信活动告警管理对象
export function smsget(id) {
  return withExtTenantIdRequest({
    url: `/sms-service/api/smsAlarmConfig/get?id=${id}`,
    method: 'get',
  });
}

// 导出热销商品列表
export function exportHotCommodity(data) {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/shopStatistic/moreHotCommodityExport`,
    method: 'post',
    data,
    responseType: 'arraybuffer',
    timeout: 30 * 1000,
  });
}

// 概况页面-新
export function getShopStatistic(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantDistributor/getMyShopStatistic',
    method: 'post',
    data,
  });
}
// 基本信息-新
export function getBasicInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantDistributor/getBasicInfo',
    method: 'post',
    data,
  });
}
// 合同信息-新
export function getContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantDistributor/getContractInfo',
    method: 'post',
    data,
  });
}
// 商家获取已授权品牌列表-新
export function listBrandAuthorizations() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorBrandAuthorization/listBrandAuthorizations',
    method: 'get',
  });
}
// 获取全部的参数信息
export function listPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brand/list',
    method: 'post',
    data,
  });
}

// 商家端-获取我的账户(返利,保证金,余额)明细
export function listAccountDetailPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/merchantDistributor/listAccountDetailPage',
    method: 'post',
    data,
  });
}

// 授信账户-用户授信账户信息
export function saleCreditAccountMyInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/saleCreditAccount/myInfo',
    method: 'post',
    data,
  });
}

// 合同列表
export function listContract(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContract/listMyPage',
    method: 'post',
    data,
  });
}

// 分销商合同主体信息列表
export function listDistributorContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/listForDistributor',
    method: 'post',
    data,
  });
}

// 获取主体信息详情
export function getDistributorContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/getContractInfo',
    method: 'post',
    params: data,
  });
}

// 修改主体信息
export function updateDistributorContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/update',
    method: 'post',
    data,
  });
}

// 创建主体信息
export function createDistributorContractInfo(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorContractInfo/create',
    method: 'post',
    data,
  });
}

// 白名单店铺列表
export function whiteList (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorWebsiteWhitelist/myListPage',
    method: 'post',
    data
  });
}
// 白名单申请
export function whiteListCreate (data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorWebsiteWhitelist/create',
    method: 'post',
    data
  });
}
// 客户端-是否开启提现
export function distributorWithdrawRecordIzWithdraw() {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorWithdrawRecord/izWithdraw',
    method: 'get',
  });
}
// 获取经营主体信息
export function listOwnCompanyInfo () {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/customerAccount/listOwnCompanyInfo',
    method: 'post'
  });
}

// 商家端-余额提现申请列表查询
export function distributorWithdrawRecordList(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/distributorWithdrawRecord/listPageApp',
    method: 'post',
    data,
  });
}

// 商家端-获取账户（用于显示返利余额）
export function merchantCustomerAccountListMyAccounts(accountType = '') {
  return withExtTenantIdRequest({
    url: `/soyoungzg/api/merchantCustomerAccount/listMyAccounts?accountType=${accountType}`,
    method: 'get',
  });
}