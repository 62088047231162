import composeMiddleware from '@/utils/composeMiddleware';
// 控制滚动条是否自动沉底
export default function genAutoScrollController(options = {}) {
  const {
    // 获取滚动区域元素
    getScrollElement,
    // 滚动条沉底开关
    useStateChange
  } = options;
  return function autoScrollController(callback, middleware) {
    return composeMiddleware(
      async (ctx, next) => {
        ctx.$options = options;
        ctx.getScrollElement = getScrollElement;
        await next();
      },
      ...[middleware, useStateChange].filter(func => !!func),
      async (_, next) => {
        await callback();
        await next();
      }
    )();
  };
}
