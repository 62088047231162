import ButtonHoc from './ButtonHoc';
export default {
  data() {
    return {};
  },
  components: {
    ButtonHoc
  },
  methods: {

  }
};
