import getEmotionsV1 from '@/helper/wx-emotions-v1.js';
import getEmotionsV2 from '@/helper/wx-emotions-v2.js';
import Emotion from '@/helper/Emotion';
import sprites from '@/assets/icon_emotion_panel.2x49d02c.png';

// 表情包处理工具函数
export default class EmotionUtil {
  constructor(options = {}) {
    this.$options = options;
    const {
      // 基准尺寸
      baseSize = 30
    } = options;
    this.baseSize = baseSize;

    this.$getEmotions = this.getEmotions();
  }
  // 获取表情选择列表 以v2为基准
  get emotions() {
    return this.$getEmotions('v2');
  }
  // 将内容解析成表情
  parseHTMLContent(content) {
    const emotions = this.$getEmotions();
    let result = content;
    const sortList = emotions.sort(
      (acc, cur) => cur.code.length - acc.code.length
    );
    sortList.forEach(emotion => {
      const { code } = emotion;
      let idx = result.indexOf(code);
      while (idx !== -1) {
        result = `${result.substring(0, idx)}${emotion.html}${result.substr(
          idx + code.length
        )}`;
        idx = result.indexOf(code);
      }
    });
    return result;
  }
  // 格式化表情数据
  parseEmotion = version => {
    const { baseSize } = this;
    return emotion =>
      new Emotion({
        ...emotion,
        baseSize,
        sprites,
        version
      });
  };
  // 获取所有表情包
  getEmotions() {
    const { parseEmotion } = this;
    const emotions = getEmotionsV1()
      .map(parseEmotion('v1'))
      .concat(getEmotionsV2().map(parseEmotion('v2')));
    return version =>
      version ? emotions.filter(item => item.version === version) : emotions;
  }
}
