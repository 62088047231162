import { throttle } from 'lodash';

let num = 0;
class Scroller {
  constructor({ elBodyWrapper }, { mode, boxSelector }) {
    if (!elBodyWrapper) {
      throw new Error('need have table element');
    }
    this.elBodyWrapper = elBodyWrapper;
    this.tableFixedHeaderWrappers = [];
    this.mode = mode;
    this._num = num++;

    this.boxEl = boxSelector ? document.querySelector(boxSelector) : document.documentElement;
    this.createScrollbar();

    // 监听页面resize事件
    window.addEventListener('resize', throttle(this.resize.bind(this), 1000 / 60));

    this.boxEl.addEventListener('scroll', this.onScrollBox.bind(this)); // 全局判断是否需要显示scroller

    // 自动同步,table => scroller
    elBodyWrapper.addEventListener('scroll', throttle(this.resetThumbPosition.bind(this), 1000 / 60));

    // 自动同步 scroller => table
    this.syncDestoryHandler = this.initScrollSyncHandler();


    this.resize();
  }

  // 页面/列表 大小发生变化
  resize() {
    const { top, bottom } = this.boxEl.getBoundingClientRect();
    this.viewBottomHeight = bottom;
    this.viewTopHeight = top;

    // 模拟滚动条宽度占比
    this.resetBar();
    // 模拟滚动条定位和宽度
    this.resetScroller();
    // 模拟滚动条移动位置
    this.resetThumbPosition();

    this.onScrollBox();
  }

  // 创建模拟滚动条
  createScrollbar() {
    const scroller = document.createElement('div');
    scroller.classList.add('el-scrollbar');
    scroller.style.height = '12px';
    scroller.style.position = 'fixed';
    scroller.style.bottom = 0;
    scroller.style.zIndex = 3;
    this.dom = scroller;

    const bar = document.createElement('div');
    bar.classList.add('el-scrollbar__bar', 'is-horizontal');
    bar.style.height = '12px';
    bar.style.bottom = 0;
    this.bar = bar;
    scroller.appendChild(bar);

    const thumb = document.createElement('div');
    thumb.classList.add('el-scrollbar__thumb');
    bar.appendChild(thumb);
    this.thumb = thumb;
  }

  // 设置百分比
  resetBar() {
    const { elBodyWrapper } = this;
    const widthPercentage = (elBodyWrapper.clientWidth * 100) / elBodyWrapper.scrollWidth;
    const thumbWidth = Math.min(widthPercentage, 100);
    this.thumb.style.width = `${thumbWidth}%`;

    this.isFullwidth = thumbWidth >= 100;
    this.visibleScroller(!this.isFullwidth);
  }

  // 模拟滚动条定位和宽度
  resetScroller() {
    const { elBodyWrapper, dom } = this;
    const { left, width } = elBodyWrapper.getBoundingClientRect();
    dom.style.left = left + 'px';
    dom.style.width = width + 'px';


    const viewHeight = window.innerHeight || document.documentElement.clientHeight;
    this.dom.style.bottom = viewHeight - this.viewBottomHeight + 'px';
  }

  resetThumbPosition() {
    const { elBodyWrapper } = this;
    const moveX = (elBodyWrapper.scrollLeft * 100) / elBodyWrapper.clientWidth;

    this.thumb.style.transform = `translateX(${moveX}%)`;
  }

  onScrollBox() {
    const { bottom } = this.elBodyWrapper.getBoundingClientRect();

    this.visibleScroller(bottom > this.viewBottomHeight);
  }

  initScrollSyncHandler() {
    let cursorDown = false;
    let tempClientX = 0;
    let rate = 1;

    const { thumb, elBodyWrapper, bar, boxEl } = this;

    function getRate() {
      // 计算一下变换比例，拖拽走的是具体数字，但是这个实际上应该是按照比例变的
      return bar.offsetWidth / thumb.offsetWidth;
    }

    const mouseMoveDocumentHandler = throttle(
      /** @param {MouseEvent} e */
      function (e) {
        if (cursorDown === false) {
          return;
        }
        const { clientX } = e;
        const offset = clientX - tempClientX;
        const originTempClientX = tempClientX;
        tempClientX = clientX;

        const tempScrollleft = elBodyWrapper.scrollLeft;
        elBodyWrapper.scrollLeft += offset * rate;
        if (tempScrollleft === elBodyWrapper.scrollLeft) {
          tempClientX = originTempClientX;
        }
      },
      1000 / 60
    );

    function mouseUpDocumentHandler() {
      cursorDown = false;
      boxEl.removeEventListener('mousemove', mouseMoveDocumentHandler);
      boxEl.removeEventListener('mouseup', mouseUpDocumentHandler);
      boxEl.onselectstart = null;
    }

    function startDrag(e) {
      e.stopImmediatePropagation();
      cursorDown = true;
      boxEl.addEventListener('mousemove', mouseMoveDocumentHandler);
      boxEl.addEventListener('mouseup', mouseUpDocumentHandler);
      boxEl.onselectstart = () => false;
    }

    thumb.onmousedown = function (e) {
      // prevent click event of right button
      if (e.ctrlKey || e.button === 2) {
        return;
      }

      const { clientX } = e;
      tempClientX = clientX;
      rate = getRate();
      startDrag(e);
    };

    /**
     * 点击槽快速移动
     * @param {PointerEvent} e
     */
    bar.onclick = function (e) {
      const { target } = e;
      if (target !== bar) {
        return;
      }
      rate = getRate();
      const { clientX } = e;
      let offset = 0;
      const thumbPosition = thumb.getBoundingClientRect();
      if (thumbPosition.left >= clientX) {
        offset = clientX - thumbPosition.left;
      } else {
        offset = clientX - thumbPosition.left - thumbPosition.width;
      }

      const targetScrollLeft = elBodyWrapper.scrollLeft + offset * rate;
      elBodyWrapper.scrollTo({
        left: targetScrollLeft,
        behavior: 'smooth'
      });
    };

    return function () {
      this.boxEl.removeEventListener('mouseup', mouseUpDocumentHandler);
    };
  }

  visibleScroller(visible = true) {
    this.dom.style.display = visible ? 'initial' : 'none';
  }

  visibleBar(visible) {
    if (this.isFullwidth) {
      this.bar.style.opacity = 0;
    } else {
      this.bar.style.opacity = +Boolean(this.mode === 'hover' ? visible : true);
    }
  }

  destory() {
    this.boxEl.removeEventListener('scroll', this.onScrollBox);
    this.syncDestoryHandler();
  }
}

export default {
  inserted(el, binding) {
    const { value: { mode = 'hover', boxSelector } = {} } = binding;
    const scroller = new Scroller(
      {
        elBodyWrapper: el,
      },
      {
        mode,
        boxSelector
      }
    );
    el.appendChild(scroller.dom);
    if (mode === 'hover') {
      el.addEventListener('mouseover', scroller.visibleBar.bind(scroller, true));
      el.addEventListener('mouseleave', scroller.visibleBar.bind(scroller, false));
    } else {
      scroller.visibleBar(true);
    }
    el.horizontalScroll = scroller;
  },
  componentUpdated(el) {
    if (el.horizontalScroll) {
      el.horizontalScroll.onScrollBox();
      el.horizontalScroll.resize();
    }
  },
  unbind(el) {
    el.horizontalScroll.destory();
  }
};
