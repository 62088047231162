import { eventReport } from '@/api/event-tracker';
import { getUserInfo } from '@/common/localStorage/user';
const userInfo = getUserInfo();
// import storage from '@/utils/storage';

const sourceChannel = ['utm_source', 'utm_medium', 'utm_compaign', 'utm_content', 'utm_term'];

// 工具函数
const _ = {};

_.isObject = value => {
  return value !== null && value.toString() === '[object Object]';
};

_.UUID = () => {
  return (
    Date.now() +
    '-' +
    Math.floor(1e7 * Math.random()) +
    '-' +
    Math.random()
      .toString(16)
      .replace('.', '') +
    '-' +
    String(31242 * Math.random())
      .replace('.', '')
      .slice(0, 8)
  );
};

_.decodeURIComponent = function (str) {
  let ret = str;
  try {
    ret = decodeURIComponent(str);
  } catch (e) {
    ret = str;
  }
  return ret;
};

_.getQueryParam = function (url, key) {
  key = key.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  url = _.decodeURIComponent(url);
  const reg = '[\\?&]' + key + '=([^&#]*)';
  const regExp = new RegExp(reg);
  const params = regExp.exec(url);
  return params === null || (params && typeof params[1] !== 'string' && params[1].length > 0)
    ? ''
    : _.decodeURIComponent(params[1]).replace(/\+/g, ' ');
};

_.getUtmFromUrl = () => {
  const utm = {};
  sourceChannel.forEach(v => {
    const param = _.getQueryParam(location.href, v);
    param.length && (utm[v] = param);
  });
  return utm;
};

const store = {
  _state: {},
  _commonProps: {},
  init (reporterData) {
    if (!_.isObject(reporterData)) return;
    this._commonProps = this._commonProps || {};
    Object.assign(this._commonProps, { ...reporterData.props });
    delete reporterData.props;
    this._state = { ...reporterData };
  },
  set (k, v) {
    this._state = this._state || {};
    this._state[k] = v;
    // this.save();
  },
  remove (k) {
    if (this._state && this._state[k]) {
      delete this._state[k];
    }
  },
  save () {
    const reporterData = {
      ...this._state,
      props: { ...this._commonProps }
    };
    localStorage.setItem('reporterData', JSON.stringify(reporterData));
  }
};

const eventReporter = {
  _state: {},
  _commonProps: {},
  setAnonId () {
    store.set('anonId', _.UUID());
    store.save();
  },
  setOpenId (openId) {
    store.set('openId', openId);
    store.set('anonId', openId);
    store.save();
  },
  setShopId (shopId) {
    store.set('shopId', shopId);
  },
  login (userId, mobile) {
    store.set('userId', userId);
    store.save();
    this.track('AnonUserBind', {
      anonId: store._state.anonId,
      userId,
      mobile
    });
  },
  logout () {
    store.set('anonId', _.UUID());
    store.remove('openId');
    store.remove('userId');
    store.save();
  },
  registerPage (commonProps) {
    if (!_.isObject(commonProps)) return;
    store._commonProps = Object.assign(store._commonProps, commonProps);
    store.save();
  },
  init () {
    let reporterData = localStorage.getItem('reporterData');
    if (reporterData) {
      reporterData = JSON.parse(reporterData);
      store.init(reporterData);
    }
    if (!store._state.anonId) {
      store.set('anonId', _.UUID());
    }
    store.save();
    // const utm = _.getUtmFromUrl();
  },
  utmTrack () {
    const utm = _.getUtmFromUrl();
    const utmFromStorage = localStorage.getItem('utm');
    const _utm = {};
    // for (const key in utmFromStorage) {

    // }
    sourceChannel.forEach(v => {
      _utm[v] = utm[v];
      _utm[`last_${v}`] = utmFromStorage && utmFromStorage[v];
    });
    // storage.set('utm', _utm);
    localStorage.setItem('utm', JSON.stringify(_utm));
  },
  trackPageView (route, from, data) {
    let path = route.path;
    const lastPath = from.path;
    // 如果path后面带params,则进行截取
    if (Object.keys(route.params).length > 0) {
      const paramsArray = Object.values(route.params);
      let paramsLength = 0;
      paramsArray.forEach(item => {
        paramsLength = paramsLength + item.length;
      });
      path = route.path.substring(0, route.path.length - paramsLength - paramsArray.length);
    }
    // 如果lastPath后面带params,则进行截取
    if (Object.keys(from.params).length > 0) {
      const paramsArray = Object.values(from.params);
      let paramsLength = 0;
      paramsArray.forEach(item => {
        paramsLength = paramsLength + item.length;
      });
      path = from.path.substring(0, from.path.length - paramsLength - paramsArray.length);
    }
    // 当前页面的参数
    const queryObj = route.query;
    let query = '';
    for (const key in queryObj) {
      query += '&' + key + '=' + queryObj[key];
    }
    for (const key in route.params) {
      query += '&' + key + '=' + route.params[key];
    }
    query = query.slice(1);
    // 上级页面的参数
    const lastQueryObj = from.query;
    let lastQuery = '';
    for (const key in lastQueryObj) {
      lastQuery += '&' + key + '=' + lastQueryObj[key];
    }
    for (const key in from.params) {
      lastQuery += '&' + key + '=' + from.params[key];
    }
    lastQuery = lastQuery.slice(1);
    // const name = document.title;
    const name = route.meta.title || '水羊直供';
    const reporterData = {
      path,
      query,
      lastQuery,
      name,
      lastPath,
      ...data
    };
    this.track('PageView', reporterData);
  },
  trackClick (route, data) {
    let path = route.path;
    // 如果path后面带params,则进行截取
    if (route.params !== {}) {
      const paramsArray = Object.values(route.params);
      let paramsLength = 0;
      paramsArray.forEach(item => {
        paramsLength = paramsLength + item.length;
      });
      path = route.path.substring(0, route.path.length - paramsLength - paramsArray.length);
    }
    const search = location.search;
    let query;
    if (search && search.length > 0 && search.startsWith('?')) {
      query = search.substring(1);
    }
    const reporterData = {
      path,
      ...data
    };
    if (query) reporterData.query = query;
    this.track('Click', reporterData);
  },
  trackSearch (route, data) {
    let path = route.path;
    // 如果path后面带params,则进行截取
    if (route.params !== {}) {
      const paramsArray = Object.values(route.params);
      let paramsLength = 0;
      paramsArray.forEach(item => {
        paramsLength = paramsLength + item.length;
      });
      path = route.path.substring(0, route.path.length - paramsLength - paramsArray.length);
    }
    const search = location.search;
    let query;
    if (search && search.length > 0 && search.startsWith('?')) {
      query = search.substring(1);
    }
    const reporterData = {
      path,
      ...data
    };
    if (query) reporterData.query = query;
    this.track('Search', reporterData);
  },
    // 推品清单浏览事件
    trackRecommendPlanDetail(route, from, data) {
      let path = route.path;
      const lastPath = from.path;
      // 如果path后面带params,则进行截取
      if (Object.keys(route.params).length > 0) {
        const paramsArray = Object.values(route.params);
        let paramsLength = 0;
        paramsArray.forEach(item => {
          paramsLength = paramsLength + item.length;
        });
        path = route.path.substring(0, route.path.length - paramsLength - paramsArray.length);
      }
      // 如果lastPath后面带params,则进行截取
      if (Object.keys(from.params).length > 0) {
        const paramsArray = Object.values(from.params);
        let paramsLength = 0;
        paramsArray.forEach(item => {
          paramsLength = paramsLength + item.length;
        });
        path = from.path.substring(0, from.path.length - paramsLength - paramsArray.length);
      }
      // 当前页面的参数
      const queryObj = route.query;
      let query = '';
      for (const key in queryObj) {
        query += '&' + key + '=' + queryObj[key];
      }
      for (const key in route.params) {
        query += '&' + key + '=' + route.params[key];
      }
      query = query.slice(1);
      // 上级页面的参数
      const lastQueryObj = from.query;
      let lastQuery = '';
      for (const key in lastQueryObj) {
        lastQuery += '&' + key + '=' + lastQueryObj[key];
      }
      for (const key in from.params) {
        lastQuery += '&' + key + '=' + from.params[key];
      }
      lastQuery = lastQuery.slice(1);
      const reportData = {
        path,
        query,
        lastQuery,
        lastPath,
        ...data
      };
      this.track('RecommendPlanDetail', { ...reportData });
    },
  trackGoodsDetail (data, referer) {
    let source = '99';
    switch (referer) {
      case 'home':
        source = '1';
        break;
      case 'category':
        source = '2';
        break;
      case 'cart':
        source = '3';
        break;
      case 'OrderDetail':
        source = '4';
        break;
      // case 'pages/member/home/index':
      //   source = '5';
      //   break;
      case 'SearchResultList':
        source = '6';
        break;
      // 客服 预留
      // case 'pages/cart':
      //   source = '7';
      //   break;
      case 'memberGiftRecords':
        source = '8';
        break;
      case 'MiniPage':
        source = '10';
        break;
      default:
        source = '99';
        break;
    }
    if (!referer) {
      source = '9';
    }
    data.source = source;
    this.track('GoodsDetail', data);
  },
  track (event, data) {
    const reporterData = {
      event,
      ...store._state,
      ...store._commonProps,
      ...data
    };
    const userInfomation =  getUserInfo();
    if (userInfomation && userInfomation.memberId) {
      reporterData.userId = userInfomation.memberId;
      reporterData.anonId = userInfomation.memberId;
    }
    if (reporterData.path) {
      reporterData.path = reporterData.path.substring(0, reporterData.path.indexOf('?') >= 0 ? reporterData.path.indexOf('?') : reporterData.path.length);
      if (reporterData.path === '/' || !reporterData.path) {
        reporterData.path = '/home';
      }
    }
    eventReport(reporterData);
  },
  // 获取上级页面router对象
  getLastPage () {
   // const pages = getCurrentPages() || [];
    //if (pages.length > 0) {
      // 有上一级页面
    //  const lastPage = pages[pages.length - 2];
    //  return lastPage;
    //}
  }
};

export default eventReporter;
