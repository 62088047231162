import sensors from "sa-sdk-javascript";
const is_prod = process.env.VUE_APP_ENV_CONFIG === "prod";
let server_url = "https://sensorsdata.syounggroup.com/sa?project=shuiyangtang";
if (!is_prod) {
  server_url += "test";
}
const scReporter = {
  init() {
    sensors.use("PageLeave");
    sensors.init({
      server_url,
      is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
      use_client_time: true,
      send_type: "beacon",
      heatmap: {
        //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
        clickmap: "default",
        //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
        scroll_notice_map: "default "
      }
    });
    // 注册公共属性
    sensors.register({
      extTenantId: "soyoung-zg",
      clientType: "merchant_pc",
      tenantId: "soyoung-zg"
    });
  },
  track(event, properties) {
    sensors.track(event, properties);
  },
  login(id) {
    sensors.login(id);
  }
};
export default scReporter;
