import Cookies from 'js-cookie';

export function getItem(key) {
  const obj = Cookies.get(key);
  if (obj) {
    return JSON.parse(obj);
  }
  return null;
}
export function setItem(key, val) {
  Cookies.set(key, JSON.stringify(val));
}
export function removeItem(key) {
  Cookies.remove(key);
}
