import { trigger } from '@/utils';
// 金额两位小数（v-money）指令
const money = {
  bind: function (el) {
    const input = el.getElementsByTagName('input')[0]; // 读取 el-input 下的input元素
    // const maxLength = input.maxLength; // 金额都限制最多maxLength-2位数，加小数点后两位，总共maxLength个数
    input.onkeyup = function (e) {
      input.value = input.value.replace(/[^\d\.]/g, ''); // 限制不能输字母
      input.value = input.value.replace(/^\./g, '');
      input.value = input.value.replace(/\.{2,}/g, '.');
      input.value = input.value.replace(/\.{2,}/g, '.');
      input.value = input.value
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
      input.value = input.value
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
      input.value = input.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      // input.value = trimCheck(input.value);
      trigger(input, 'input');
    };
    input.onblur = function (e) {
      input.value = input.value.replace(/[^\d\.]/g, '');
      input.value = input.value.replace(/^\./g, '');
      input.value = input.value.replace(/\.{2,}/g, '.');
      input.value = input.value.replace(/\.{2,}/g, '.');
      input.value = input.value
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
      input.value = input.value
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.');
      input.value = input.value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      if (input.value === '0' && input.value.length > 1) {
        // 输入0的时候转成空    或者可自行调节   0.00  看需求
        input.value = '';
      }
      if (input.value.split('.')[1] === '') {
        input.value = input.value.split('.')[0];
      }
      const reg1 = /0*([1-9]\d*|0\.\d+)/;
      const reg2 = /(?:\.0*|(\.\d+?)0+)$/;
      const value = input.value;
      input.value = value.replace(reg1, '$1').replace(reg2, '$1'); // 去掉数字（整数、小数）前面多余的零
      input.value = trimCheck(input.value);

      trigger(input, 'input');
    };
  }
};
export default money;

// 为空自动为0
function trimCheck(value) {
  if (value.trim() === '') {
    value = '0';
  }
  return value;
}
