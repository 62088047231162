<template>
  <el-menu class="navbar" mode="horizontal">
    <breadcrumb></breadcrumb>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
export default {
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapGetters(['carNumber']),
  },
  created() {
    this.getCarNum();
  },
  methods: {
    getCarNum() {
      this.$store.dispatch('getCountStatisticsHot');
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.navbar {
  height: 64px;
  line-height: 64px;
  border: 0 !important;
  display: flex;
}
</style>
