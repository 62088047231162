//import fetch from './fetchInterceptor';
import { withExtTenantIdRequest } from '@/utils/request';

// 发送消息给客服
export function sendToCs(data) {
  return withExtTenantIdRequest({
    url: '/chat-service/api/chatRecord/sendToCs',
    method: 'post',
    data
  });
}

// 获取会话历史消息
export function fetchMsgPageList(data) {
  return withExtTenantIdRequest({
    url: '/chat-service/api/chatRecord/historyListPage',
    method: 'post',
    data
  });
}

// 获取会话历史消息   匿名模式
export function fetchMsgPageListByAnon(data) {
  return withExtTenantIdRequest({
    url: '/chat-service/api/chatRecord/historyListPageByAnon',
    method: 'post',
    data
  });
}

// 发送消息给客服   匿名模式
export function sendToCsByAnon(data) {
  return withExtTenantIdRequest({
    url: '/chat-service/api/chatRecord/sendToCsByAnon',
    method: 'post',
    data
  });
}

// 是否能显示客服按钮
export function getSwitchByType() {
  return withExtTenantIdRequest({
    url: '/ocean/api/shopModuleSwitch/getSwitchByType?type=CHAT_SHOW_CONFIG',
    method: 'get',
  });
}

// 上传图片
export function uploadChatImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  return withExtTenantIdRequest({
    url: '/file-service/api/image/upload',
    method: 'post',
    data: formData,
    params: 'image/newretail/chat'
  });
}

export default {
  sendToCs,
  fetchMsgPageList,
  uploadChatImage
};
